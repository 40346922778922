import { Grid, Box } from '@material-ui/core'
import { useState, useEffect } from 'react'
import Link from '../../components/Link'
import { StarRating } from '../../components/StarRating'
import AmazonProductImage, { GenericProductImage } from '../../components/AmazonProductImage'

const itemStyle = {
  // Negate effects of negative margin in grid when in column orientation
  paddingTop: 20,
  paddingBottom: 20,
}

const tileSx = {
  borderColor: 'var(--primary-color)',
  borderWidth: 1,
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 10,
  alignItems: 'center',
  textAlign: 'center',
  padding: '10px',
  height: '100%'
}


const MoreLikeThis = ({ apiResolver, productId }) => {

  const [matches, loading] = useMoreLikeThis(apiResolver, productId)


  const { results, type = 'similar' } = matches || {}

  const renderTitle = () => {
    if (loading) return 'Loading recommendations...'
    if (type === 'also_liked') return 'Reviewers who liked this also liked'
    return 'Similar products'
  }

  const renderTiles = () => {
    if(loading) return [1,2,3].map(key => <Placeholder key={key}/>)
    if(results) return results.map(m => <Match key={m.id} match={m} />)
    return null
  }

  return (
    <section style={{ textAlign: 'left' }}>
      <h3>{renderTitle()}</h3>
      <Grid container  spacing={2} alignItems="stretch" direction="row">
        {renderTiles()}
      </Grid>
    </section>
  )
}

export default MoreLikeThis

const Tile = ({ children }) => {
  return (
    <Grid item xs={12} md={4} style={itemStyle}>
      <Box sx={tileSx}>
        {children}
      </Box>
    </Grid>
  )

}

const Match = ({ match }) => {
  const { name = '', id, asin, rating, review_count } = match || {}

  return (
    <Tile>
      <Link to={`/product/${id}`}>
        {asin ? <AmazonProductImage asin={asin} fallbackUrl='/shampoo.icon.svg'/> : <GenericProductImage />}
        <br />
        {name}
      </Link>
      {Boolean(review_count) && <StarRating rating={rating} />}
    </Tile>
  )
}


const Placeholder = () => {
  return (
    <Tile>
      <div style={{ height: '200px' }} />
    </Tile>
  )

}


const useMoreLikeThis = (apiResolver, productId) => {

  const [matches, setMatches] = useState(null)

  useEffect(() => {
    apiResolver
      .getProductApi()
      .similar(productId)
      .then(setMatches)
      .catch(() => {
        setMatches(null)
      })
  }, [apiResolver, productId])

  return [matches, matches === null]
}