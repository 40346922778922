import React from "react";

import Layout from "../../components/Layout";
import PasswordLoginForm from "./PasswordLoginForm";

async function action({ apiResolver }) {
  const loggedIn = apiResolver.isAuthenticated();
  if (loggedIn) {
    return { redirect: "/" };
  }

  return {
    title: "Search",
    component: (
      <Layout>
        <PasswordLoginForm />
      </Layout>
    )
  };
}

export default action;
