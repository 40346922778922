import React from "react";

import Layout from "../../components/Layout";
import FacebookLogin from "./FacebookLogin";

async function action({ query }) {
  return {
    title: "Logging in via Facebook...",
    component: (
      <Layout>
        <FacebookLogin code={query.code} />
      </Layout>
    )
  };
}

export default action;
