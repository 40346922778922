import React from "react";
import { Opacity } from "@material-ui/icons";
import { arrayOf, shape, bool } from "prop-types";
import CollapsePanel from "./CollapsePanel";
import styles from "./IngredientFilters.module.css";
import Loading from "../../components/Loading";

class IngredientFilters extends React.Component {
  renderIngredientFilter = ingFilter => {
    const { loading } = this.props;

    const {
      id,
      description,
      name,
      filter_ingredients,
      primary_color,
      secondary_color
    } = ingFilter;

    let matches = null;
    if (filter_ingredients && filter_ingredients.length > 0) {
      matches = filter_ingredients.map(item => `"${item.ingredient.name}"`);
    }

    return (
      <CollapsePanel
        key={id}
        icon={<Opacity />}
        primaryColor={primary_color || "650d35"}
        secondaryColor={secondary_color || "c389a4"}
        loading={loading}
        title={`Contains: ${name}`}
        className={styles.IngredientFilters}
      >
        <p>{description}</p>
        {matches && <p>Matching ingredients: {matches.join(", ")}</p>}
      </CollapsePanel>
    );
  };

  render() {
    const { ingredient_filters, loading } = this.props;

    if (loading) {
      return <Loading loading={loading} />;
    }

    if (ingredient_filters) {
      return ingredient_filters.map(ingFilter =>
        this.renderIngredientFilter(ingFilter)
      );
    }

    return null;
  }
}

IngredientFilters.propTypes = {
  ingredient_filters: arrayOf(shape({})),
  loading: bool.isRequired
};

IngredientFilters.defaultProps = {
  ingredient_filters: undefined
};

export default IngredientFilters;
