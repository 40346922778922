import React from "react";
import { withStyles } from "@material-ui/styles";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import CopyButton from "./CopyButton";
import ButtonLink from "./ButtonLink";

const StyledCard = withStyles({
  root: {
    marginBottom: 25
  }
})(Card);

const CabinetVisibility = ({ cabinet, showEditLink = false }) => {
  const shareUrl =
    cabinet &&
    cabinet.public &&
    `${window.location.protocol}//${window.location.host}/cabinets/public/${cabinet.share_uuid}`;

  return (
    <StyledCard>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Sharing & Visibility
        </Typography>
        <Typography variant="body2">
          {cabinet.public ? (
            <>
              Use the following link to share this cabinet with friends, family
              and the internet:
              <br />
              <a href={shareUrl} style={{ wordWrap: "break-word" }}>
                {shareUrl}
              </a>
            </>
          ) : (
            <>
              This cabinet is private and cannot be viewed by anyone other than
              you. If you wish to share it, change the visibility to
              &quot;Public&quot;.
            </>
          )}
        </Typography>
      </CardContent>
      <CardActions>
        {showEditLink && (
          <ButtonLink size="small" to={`/cabinets/${cabinet.id}/edit`}>
            Change
          </ButtonLink>
        )}

        {shareUrl && (
          <CopyButton copyData={shareUrl}>Copy Share Link</CopyButton>
        )}
      </CardActions>
    </StyledCard>
  );
};

export default CabinetVisibility;
