import React, { useEffect, useState } from "react";
import { withApiContext } from "../../apis/context";
import history from "../../history";
import Link from "../../components/Link";

const FacebookLogin = ({ code, apiResolver }) => {
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    if (code) {
      apiResolver
        .getAuthTokenApi()
        .loginFacebook({ params: { code } })
        .then(token => {
          apiResolver.initAuth(token);
          history.push("/profile");
        })
        .catch(({ detail, non_field_errors = ["Unknown error"] }) => {
          setStatus(detail || non_field_errors.join(", "));
        });
    } else {
      setStatus(false);
    }
  }, []);

  return (
    <section>
      <h3>Logging in with Facebook...</h3>
      {status === undefined ? (
        <p>Please wait while we log you in...</p>
      ) : (
        <p>
          Oops! Something went wrong. <Link to="/login">Please try again?</Link>
          <br />
          <br />
          <em>{status}</em>
        </p>
      )}
    </section>
  );
};

export default withApiContext()(FacebookLogin);
