import React from "react";
import { NavigateNext } from "@material-ui/icons";
import { shape } from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./Submit.module.css";
import Link from "../../components/Link";
import { withApiContext } from "../../apis/context";
import { Form } from "../../components/Form";

const fields = [
  {
    name: "name",
    type: "input",
    label: "Missing Product Name (required)",
    placeholder: "Product Name",
    default: "",
    required: true
  },
  {
    name: "barcode",
    type: "input",
    label: "Missing Product Barcode",
    help:
      "Please include all digits including first and last digits outside bars",
    placeholder: "Product Name",
    default: "",
    renderBefore: () => (
      <div className={styles.Barcode}>
        <img alt="CurlScan" src="/barcode.png" height={150} />
      </div>
    )
  },
  {
    name: "approved",
    required: true,
    type: "select",
    label: "Curly Girl (CG) Approved",
    default: "?",
    fieldProps: {
      options: [
        { value: "y", name: "✓ Approved" },
        { value: "n", name: "✗ Not Approved" },
        { value: "?", name: "? I Don't Know" }
      ]
    }
  }
];

const initialState = {
  disabled: undefined,
  submitted: false,
  submitting: false
};

class Submit extends React.PureComponent {
  state = initialState;

  componentDidMount() {
    this.fetchSiteSetting();
  }

  fetchSiteSetting = () => {
    const { apiResolver } = this.props;
    apiResolver
      .getSiteSettingApi()
      .list({
        filters: { key: "allow_submissions" },
        pagination: { page: 1, size: 50 }
      })
      .then(page => {
        this.setState({
          disabled:
            page.results.length && page.results[0].boolean_value === false
        });
      })
      .catch(() => {
        this.setState({
          disabled: false
        });
      });
  };

  handleSubmit = (formData, setFormErrors) => {
    const { apiResolver } = this.props;

    this.setState({ submitting: true });
    apiResolver
      .getProductApi()
      .create({
        params: formData
      })
      .then(() => this.setState({ submitted: true, submitting: false }))
      .catch(err => {
        setFormErrors(err);
        this.setState({
          submitted: false,
          submitting: false
        });
      });
  };

  handleReset = () => {
    this.setState({ ...initialState });
    this.fetchSiteSetting();
  };

  render() {
    const { disabled, submitting, submitted } = this.state;

    return (
      <section className={styles.Container}>
        <h4>Submit Product</h4>
        {disabled === undefined && <LinearProgress />}

        {submitted && (
          <p>
            Thanks for adding to the database! Click <Link to="/">here</Link> to
            return to the search page or{" "}
            <Link to="/submit" onClick={this.handleReset}>
              submit another product.
            </Link>
          </p>
        )}

        {disabled && (
          <p>
            Hi! We have received a lot of product submissions, so we
            have temporarily turned off this form to allow ourselves to catch
            up. We'll turn it back on as soon as we've added all of the
            submissions, so check back soon!
          </p>
        )}

        {!submitted && disabled === false && (
          <>
            <p>
              Please use this form to report missing or incorrect products or
              barcodes.
            </p>
            <Form
              onSubmit={this.handleSubmit}
              saving={submitting}
              fields={fields}
              submitText="Submit"
              submitIcon={<NavigateNext />}
            />
          </>
        )}
      </section>
    );
  }
}

Submit.propTypes = {
  apiResolver: shape({}).isRequired
};

export default withApiContext()(Submit);
