import React from "react";
import qs from "query-string";

import Layout from "../../components/Layout";
import Search from "./Search";
import history from "../../history";

async function action({ query }) {

  const onUpdateSearch = ({ search, ...filters }) => {
    history.push(`/?${qs.stringify({ search, ...filters })}`);
  };

  return {
    title: "CurlScan - Curly Girl Method Approved Product Barcode Scanner",
    component: (
      <Layout>
        <Search search={query.search} onUpdateSearch={onUpdateSearch} />
      </Layout>
    )
  };
}

export default action;
