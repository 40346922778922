import React, { useState } from "react";
import { Input as InputIcon } from "@material-ui/icons";
import { Form } from "../../components/Form";
import styles from "./ForgotPasswordForm.module.css";
import { withApiContext } from "../../apis/context";

const fields = [
  {
    name: "email",
    type: "input",
    label: "Email",
    placeholder: "email"
  }
];

const ForgotPasswordForm = ({ apiResolver, email }) => {
  const [loading, setLoading] = useState(false);
  const [reminded, setReminded] = useState(false);

  const handleSubmit = (formData, setFormErrors) => {
    setLoading(true);
    apiResolver
      .getUserApi()
      .registerPassword({
        params: {
          ...formData,
          forgot: true
        }
      })
      .then(() => {
        setReminded(true);
      })
      .catch(err => {
        setLoading(false);
        setFormErrors(err);
      });
  };

  return (
    <section className={styles.Container}>
      {reminded ? (
        <section>
          <h3>Check your email!</h3>
          <p>
            If an account exists for this email you will receive a password
            reset link that you can use to set a new password. Be sure to check
            your spam folder if you do not receive right away.
          </p>
        </section>
      ) : (
        <section>
          <h3>Forgot Password</h3>
          <p>
            Having trouble logging in? No worries, just enter the email address
            that you think you signed up with and we will send you a link to
            reset your password.
          </p>
          <Form
            fields={fields}
            initialValues={{ email: email || "" }}
            onSubmit={handleSubmit}
            saving={loading}
            submitIcon={<InputIcon/>}
            submitText="Reset Password"
          />
        </section>
      )}
    </section>
  );
};

export default withApiContext()(ForgotPasswordForm);
