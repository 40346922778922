import UniversalRouter from "universal-router";
import loginRequiredAction from "./loginRequired";
import permissionDeniedAction from "./permissionDenied";
import errorAction from "./error";

import advancedAction from "./advanced";
import approvedAction from "./approved";
import loginAction from "./login";
import registerAction from "./register";
import productAction from "./product";
import searchAction from "./search";
import submitAction from "./submit";
import feedbackAction from "./feedback";
import latestAction from "./latest";
import activateAction from "./activate";
import forgotAction from "./forgot";
import facebookAction from "./facebook";
import sharedCabinetAction from "./cabinet";
import notFoundAction from "./notFound";

const routes = [
  {
    path: "/login",
    name: "login",
    action: loginAction
  },
  {
    path: "/register",
    name: "register",
    action: registerAction
  },
  {
    path: "/forgot",
    name: "forgot",
    action: forgotAction
  },
  {
    path: "/activate",
    name: "activate",
    action: activateAction
  },
  {
    path: "/facebook",
    name: "facebook",
    action: facebookAction
  },
  {
    path: "/product/:id(\\d+)",
    name: "product",
    action: productAction
  },
  {
    path: ["/approved/:advanced(advanced)"],
    name: "advanced",
    action: advancedAction
  },
  {
    path: ["/approved/:letter([A-Za-z])", "/approved"],
    name: "approved",
    action: approvedAction
  },

  {
    path: "/privacy",
    name: "privacy",
    load: () => import(/* webpackChunkName: 'privacy' */ "./privacy")
  },

  {
    path: "/faq",
    name: "faq",
    load: () => import(/* webpackChunkName: 'faq' */ "./faq")
  },

  {
    path: "/",
    name: "home",
    action: searchAction
  },

  {
    path: "/submit",
    name: "submit",
    action: submitAction
  },
  {
    path: "/feedback",
    name: "feedback",
    action: feedbackAction
  },
  {
    path: "/blog",
    name: "blog",
    load: () => import(/* webpackChunkName: 'blog' */ "./blog")
  },
  {
    path: "/latest",
    name: "latest",
    action: latestAction
  },
  {
    path: "/tos",
    name: "tos",
    load: () => import(/* webpackChunkName: 'tos' */ "./tos")
  },
  {
    path: ["/blog/:id(\\d+)/:slug([a-z0-9_-]{5,})"],
    name: "blogPost",
    load: () => import(/* webpackChunkName: 'blogPost' */ "./blogPost")
  },
  {
    path: "/cabinets/public/:shareUuid([a-zA-Z0-9]+)",
    name: "cabinet",
    action: sharedCabinetAction
  },
  {
    path: "/popular",
    name: "leaderboard",
    load: () => import( /* webpackChunkName: 'leaderboard' */ "./leaderboard")
  },
  {
    path: "",
    children: [
      {
        path: ["/blog/:id(\\d+)/edit", "/blog/:id(new)"],
        name: "blogPostEdit",
        load: () =>
          import(/* webpackChunkName: 'blogPostEdit' */ "./blogPostEdit")
      },
      {
        path: "/manage",
        name: "manage",
        children: [
          {
            path: ["/products"],
            name: "manageProducts",
            load: () =>
              import(
                /* webpackChunkName: 'manageProducts' */ "./manageProducts"
              )
          },
          {
            path: ["/filters"],
            name: "manageFilters",
            load: () =>
              import(/* webpackChunkName: 'manageFilters' */ "./manageFilters")
          },
          {
            path: ["/site-settings"],
            name: "manageSiteSettings",
            load: () =>
              import(
                /* webpackChunkName: 'manageSiteSettings' */ "./manageSiteSettings"
              )
          },
          {
            path: ["/submissions"],
            name: "manageSubmissions",
            load: () =>
              import(
                /* webpackChunkName: 'manageSubmissions' */ "./manageSubmissions"
              )
          }
        ]
      },
      {
        path: "/profile",
        name: "profile",
        load: () => import(/* webpackChunkName: 'profile' */ "./profile")
      },
      {
        path: "/rated",
        name: "rated",
        load: () =>
          import(/* webpackChunkName: 'ratedProducts' */ "./ratedProducts")
      },
      {
        path: "/cabinets/:id(\\d+)/edit",
        name: "cabinetEdit",
        load: () =>
          import(/* webpackChunkName: 'cabinetEdit' */ "./cabinetEdit")
      },
      {
        path: "/cabinets/:id(\\d+)",
        name: "cabinetView",
        load: () =>
          import(/* webpackChunkName: 'cabinetView' */ "./cabinetView")
      },
      {
        path: "/cabinets",
        name: "cabinets",
        load: () => import(/* webpackChunkName: 'cabinets' */ "./cabinets")
      }
    ]
  }
];

const router = new UniversalRouter(routes, {
  resolveRoute(context, params) {
    const { route } = context;

    if (typeof route.action === "function") {
      return route
        .action(context, params)
        .then(result => ({ ...result, name: route.name }));
    }
    if (typeof route.load === "function") {
      return route
        .load()
        .then(action => action.default(context, params))
        .then(result => ({ ...result, name: route.name }));
    }

    return undefined;
  },
  errorHandler(error) {
    if (error.status === 404) {
      return notFoundAction();
    }
    if (error.status === 403) {
      return permissionDeniedAction();
    }
    if (error.status === 401) {
      return loginRequiredAction();
    }
    return errorAction();
  }
});

export default router;
