import React from "react";
import { string, bool } from "prop-types";
import styles from "./FilterLabel.module.css";
import Link from "../../components/Link";

const FilterLabel = ({ label, to, selected }) => {
  return !selected ? (
    <span
      className={`${styles.LabelLink} ${selected && styles.LabelLinkSelected}`}
    >
      <Link to={to}>&nbsp;{label}&nbsp;</Link>
    </span>
  ) : (
    <span className={`${styles.LabelLink} ${styles.LabelLinkSelected}`}>
      &nbsp;{label}&nbsp;
    </span>
  );
};

FilterLabel.propTypes = {
  label: string.isRequired,
  to: string.isRequired,
  selected: bool.isRequired
};

FilterLabel.defaultProps = {};

export default FilterLabel;
