import React from "react";
import { Star } from "@material-ui/icons";
import CollapsePanel from "./CollapsePanel";
import styles from "./ProductReview.module.css";
import { StarRating } from "../../components/StarRating";
import YourRating from "./YourRating";
import RatingAggregates from "./RatingAggregates";

export const ProductReview = ({ product, onChange }) => {
  const { rating = {} } = product;
  const { count, average } = rating;

  const hasRating = count > 0;

  return (
    <CollapsePanel
      icon={<Star />}
      secondaryColor="aaaaaa"
      primaryColor="000000"
      onExpand={null}
      loading={false}
      title={
        <>
          CurlScan User Rating{" "}
          {hasRating && (
            <section>
              <StarRating rating={average} />({count} reviews)
            </section>
          )}
        </>
      }
      className={styles.ProductReview}
    >
      <br />
      <YourRating product={product} onChange={onChange} />
      <br />

      {hasRating ? (
        <RatingAggregates product={product} />
      ) : (
        <>Be the first to rate this product.</>
      )}
    </CollapsePanel>
  );
};
