import React from "react";
import { Typography } from "@material-ui/core";

import Layout from "../../components/Layout";

export async function action() {
  return {
    title: "Permission Denied",
    component: (
      <Layout>
        <Typography variant="h3" gutterBottom>
          Permission Denied
        </Typography>
        <Typography variant="body1">
          You do not have permission to perform this action.
        </Typography>
      </Layout>
    )
  };
}

export default action;
