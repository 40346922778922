import React from "react";
import qs from "query-string";
import { string, bool } from "prop-types";
import { List } from "@material-ui/icons";
import CollapsePanel from "./CollapsePanel";
import styles from "./ProductIngredients.module.css";
import Link from "../../components/Link";

class ProductIngredients extends React.Component {
  render() {
    const { ingredients_text, loading, productName = "" } = this.props;

    const feedbackMessage = `I have a question about: ${productName}`;

    let content;
    if (ingredients_text) {
      content = (
        <>
          Products may have more than one formula. The status of this product
          was determined based on the following ingredients:
          <p>
            <i>{ingredients_text}</i>
          </p>
          <b>
            Formulas may vary or change at any time. Always check your products
            for unapproved ingredients.
          </b>
        </>
      );
    } else {
      content = (
        <p>
          These ingredients have not been added to the database yet. If you have
          questions please use the{" "}
          <Link to={`/feedback?${qs.stringify({ message: feedbackMessage })}`}>
            feedback form
          </Link>
          .
        </p>
      );
    }

    return (
      <CollapsePanel
        icon={<List />}
        primaryColor="0d6165"
        secondaryColor="89c1c3"
        onExpand={null}
        loading={loading}
        title="Tap to Compare Your Product Ingredients"
        className={styles.ProductIngredients}
      >
        {content}
      </CollapsePanel>
    );
  }
}

ProductIngredients.propTypes = {
  ingredients_text: string,
  loading: bool.isRequired
};

ProductIngredients.defaultProps = {
  ingredients_text: undefined
};

export default ProductIngredients;
