import React from "react";
import styles from "./Error.module.css";

const Error = ({ error = false }) => {
  const errorMsg =
    typeof error === "string"
      ? error
      : "Oops! An error occurred. Please try again.";
  return error && <section className={styles.Error}>{errorMsg}</section>;
};

export default Error;
