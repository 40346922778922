import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography
} from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import { withApiContext } from "../../apis/context";
import history from "../../history";
import Loading from "../../components/Loading";
import Link from "../../components/Link";
import Config from "../../config";
import ButtonLink from "../../components/ButtonLink";

const CabinetMenuItem = ({ cabinet, isAdded, onAdd, onRemove }) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    if (!loading) {
      setLoading(true);
      (isAdded ? onRemove : onAdd)(cabinet.id).then(() => setLoading(false));
    }
  };
  return (
    <ListItem onClick={onClick} button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={isAdded}
          tabIndex={-1}
          disableRipple
          onChange={onClick}
        />
      </ListItemIcon>
      <ListItemText>{cabinet.name}</ListItemText>
    </ListItem>
  );
};

const CabinetMenuList = withApiContext()(({ apiResolver, product }) => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  const [cabinets, setCabinets] = useState(null);

  const isAuthenticated = apiResolver.isAuthenticated();

  const fetchCabinets = () => {
    if (isAuthenticated) {
      setLoading(true);
      apiResolver
        .getProductApi()
        .cabinets(product.id)
        .then(setCabinets)
        .catch(() => setCabinets([]))
        .finally(() => setLoading(false));
    }
  };

  useEffect(fetchCabinets, [apiResolver, product]);

  const updateProductInCabinetState = (cabinet, newState) => {
    setCabinets(cabinets =>
      cabinets.map(c =>
        c.id === cabinet.id
          ? {
              ...c,
              contains_product: newState
            }
          : c
      )
    );
  };

  const onAdd = cabinetId => {
    return apiResolver
      .getCabinetApi()
      .products(cabinetId, { params: { add: [product.id] } })
      .then(cabinet => updateProductInCabinetState(cabinet, true))
      .catch(() => null);
  };

  const onRemove = cabinetId => {
    return apiResolver
      .getCabinetApi()
      .products(cabinetId, { params: { remove: [product.id] } })
      .then(cabinet => updateProductInCabinetState(cabinet, false))
      .catch(() => null);
  };

  const createCabinet = () => {
    setCreating(true);
    return apiResolver
      .getCabinetApi()
      .create({
        params: {
          name: "My Cabinet",
          description: "My favourite CurlScan Products.",
          public: false
        }
      })
      .then(() => fetchCabinets())
      .catch(() => history.push("/cabinets"));
  };

  return (
    <>
      <Loading loading={loading} />
      <List>
        {cabinets &&
          cabinets.length > 0 &&
          cabinets.map(c => (
            <CabinetMenuItem
              key={c.id}
              cabinet={c}
              isAdded={c.contains_product}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          ))}
      </List>
      {isAuthenticated && cabinets && cabinets.length === 0 && (
        <section>
          <Typography paragraph>
            You do not have any any Cabinets yet! You can view and manage
            Cabinets from <Link to="/cabinets">your profile.</Link>
          </Typography>
          <Typography paragraph>
            In a hurry? Click below to get started.
          </Typography>
          <Typography align="center">
            <Button
              color="primary"
              variant="contained"
              disabled={creating}
              onClick={createCabinet}
            >
              Create your first Cabinet
            </Button>
          </Typography>
        </section>
      )}
      {!isAuthenticated && (
        <section>
          <Typography paragraph>
            <b>New!</b> Login with your CurlScan account to add products to
            personalized cabinets and share them with your friends.
          </Typography>
          <Typography align="center">
            <ButtonLink to="/login" color="primary" variant="contained">
              Login
            </ButtonLink>
          </Typography>
        </section>
      )}
    </>
  );
});

const AddToCabinetMenu = withApiContext()(({ product, apiResolver }) => {
  const [opened, setOpened] = useState(false);

  const toggleMenu = () => setOpened(!opened);

  if (!apiResolver.isAuthenticated() && Config.hideRegistration) return null;

  const close = () => setOpened(false);

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<PlaylistAdd />}
        onClick={toggleMenu}
      >
        Add To Cabinet
      </Button>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle>Add To Cabinet</DialogTitle>
        <DialogContent>
          <CabinetMenuList product={product} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={close}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default AddToCabinetMenu;
