import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { Clear, Save } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import {
  Button,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress
} from "@material-ui/core";

const InputField = ({ name, value, placeholder, onChange, error, label }) => {
  return (
    <OutlinedInput
      color="secondary"
      label={label}
      name={name}
      value={value || ""}
      autoComplete="off"
      placeholder={placeholder}
      error={error}
      onChange={e => onChange({ [name]: e.target.value })}
    />
  );
};

const PasswordField = ({ name, value, onChange, label }) => {
  return (
    <OutlinedInput
      name={name}
      label={label}
      type="password"
      placeholder="password"
      value={value || ""}
      onChange={e => onChange({ [name]: e.target.value })}
    />
  );
};

const TextAreaField = ({
  name,
  value,
  onChange,
  label,
  rows = 5,
  placeholder
}) => {
  return (
    <OutlinedInput
      multiline
      name={name}
      label={label}
      value={value}
      onChange={e => onChange({ [name]: e.target.value })}
      rows={rows}
      rowsMax={rows * 2}
      placeholder={placeholder}
    />
  );
};

const SelectField = ({
  name,
  label,
  value,
  pleaseSpecify,
  options,
  onChange,
  multiple = false
}) => {
  return (
    <Select
      name={name}
      label={label}
      value={options.length ? value : ''}
      multiple={multiple}
      onChange={e => onChange({ [name]: e.target.value })}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={() => onChange({ [name]: multiple ? [] : "" })}>
            <Clear />
          </IconButton>
        </InputAdornment>
      }
    >
      {pleaseSpecify && (
        <MenuItem value="" disabled>
          Please Specify
        </MenuItem>
      )}
      {options.map(opt => (
        <MenuItem
          key={opt.key || opt.value}
          value={`${opt.value}`}
          disabled={opt.disabled}
        >
          {opt.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const SelectMultipleField = ({ ...props }) => {
  return <SelectField {...props} multiple />;
};

const CheckboxField = ({ name, value, onChange, label }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={!!value}
          onChange={e => onChange({ [name]: e.target.checked })}
        />
      }
      label={label}
    />
  );
};

const HiddenField = ({ name, value }) => {
  return <input type="hidden" name={name} value={value || ""} />;
};

const fieldTypeMap = {
  input: InputField,
  password: PasswordField,
  textarea: TextAreaField,
  select: SelectField,
  multiple: SelectMultipleField,
  checkbox: CheckboxField,
  hidden: HiddenField
};

const unlabeledTypes = ["checkbox"];

const Errors = ({ errors }) => {
  if (errors && errors.length > 0) {
    return (
      <Alert style={{ marginTop: "1em" }} severity="error">
        {errors.join(". ")}
      </Alert>
    );
  }
  return null;
};

const FormField = ({
  name,
  label,
  type,
  errors,
  fieldProps,
  renderField,
  value,
  onChange,
  renderBefore,
  placeholder,
  help
}) => {
  const fieldRenderer = renderField || fieldTypeMap[type];

  const error = errors && errors.length > 0;

  const field = fieldRenderer({
    name,
    value,
    onChange,
    placeholder,
    error,
    label,
    ...fieldProps
  });

  if (type === "hidden") {
    return field;
  }

  const helperText = error ? errors.join(". ") : help;

  const showLabel = label && !unlabeledTypes.includes(type);

  return (
    <>
      {renderBefore && renderBefore()}
      <FormControl fullWidth margin="normal" variant="outlined" error={error}>
        {showLabel && <InputLabel htmlFor={name}>{label}:</InputLabel>}
        {field}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export const FormFields = ({ data, errors, onChange, fields }) => {
  return fields.map(f => (
    <FormField
      key={f.name}
      name={f.name}
      value={data[f.name]}
      placeholder={f.placeholder}
      label={f.label}
      errors={errors ? errors[f.name] : []}
      onChange={onChange}
      type={f.type}
      renderField={f.renderField}
      renderBefore={f.renderBefore}
      fieldProps={f.fieldProps}
      help={f.help}
    />
  ));
};

export const allRequiredFieldsPresent = (formData, fields) => {
  const missing = fields.some(
    f => f.required && formData[f.name] === undefined
  );
  return !missing;
};

export const Form = ({
  initialValues,
  fields,
  onSubmit,
  saving,
  submitText = "Save",
  submitIcon = <Save />,
  validator,
  renderFields = fields => fields,
  renderActions = actions => actions
}) => {
  const [formErrors, setFormErrors] = useState({});

  const defaultFormData = {};

  // Apply defaults first
  fields.forEach(function(f) {
    defaultFormData[f.name] = f.default;
  });

  const [formData, setFormData] = useState(defaultFormData);

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors({});
    onSubmit(formData, setFormErrors);
  };

  useEffect(() => {
    if (initialValues) {
      const initialFormData = {};
      fields.forEach(function(f) {
        initialFormData[f.name] = get(
          initialValues,
          f.source || f.name,
          f.default
        );
      });
      setFormData(initialFormData);
      setFormErrors({});
    }
  }, []);

  const isValid =
    allRequiredFieldsPresent(formData, fields) &&
    (validator ? validator(formData, fields) : true);

  const fieldsContent = (
    <>
      <FormFields
        data={formData}
        errors={formErrors}
        onChange={changes => setFormData({ ...formData, ...changes })}
        fields={fields}
      />

      {formErrors && formErrors.detail && (
        <Errors errors={[formErrors.detail]} />
      )}

      {formErrors && formErrors.non_field_errors && (
        <Errors errors={formErrors.non_field_errors} />
      )}
    </>
  );

  const actionContent = (
    <Typography component="div" align="right">
      <FormControl margin="normal">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={saving ? <CircularProgress size={15} /> : submitIcon}
          type="submit"
          disabled={saving || !isValid}
        >
          {submitText}
        </Button>
      </FormControl>
    </Typography>
  );

  return (
    <form onSubmit={handleSubmit}>
      {renderFields(fieldsContent)}
      {renderActions(actionContent)}
    </form>
  );
};
