import BaseApi from "./base";

export default class AuthTokenApi extends BaseApi {
  baseName = "auth_token";

  login(options) {
    return this.create({ ...options, endpoint: "new" });
  }

  loginPassword(options) {
    return this.create({ ...options, endpoint: "login/password" });
  }

  loginFacebook(options) {
    return this.create({ ...options, endpoint: "login/facebook" });
  }

  logout(options) {
    return this.action(null, {
      ...options,
      params: { token: this.getToken() },
      endpoint: "revoke"
    });
  }

  activatePassword(options) {
    return this.create({ ...options, endpoint: "activate/password" });
  }
}
