import React from "react";
import history from "../history";
import styles from "./Link.module.css";

const Link = ({
  to,
  children,
  onClick,
  external = false,
  className = ""
}) => {
  const handleClick = e => {
    e.preventDefault();

    if (onClick) {
      onClick();
    } else {
      history.push(to);
    }
  };

  return (
    <a
      href={to || "#"}
      onClick={external ? undefined : handleClick}
      className={`${className} ${styles.Link}`}
    >
      {children}
    </a>
  );
}

export default Link;