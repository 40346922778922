import React from "react";
import { string } from "prop-types";
import { Comment } from "@material-ui/icons";
import CollapsePanel from "./CollapsePanel";
import styles from "./ProductIngredients.module.css";

class ProductNotes extends React.Component {
  render() {
    const { notes } = this.props;

    if (!notes) return null;

    return (
      <CollapsePanel
        icon={<Comment />}
        secondaryColor="aaaaaa"
        primaryColor="000000"
        onExpand={null}
        loading={false}
        title="Notes About This Product"
        className={styles.ProductIngredients}
        initialShowing
      >
        {notes}
      </CollapsePanel>
    );
  }
}

ProductNotes.propTypes = {
  notes: string
};

ProductNotes.defaultProps = {
  notes: undefined
};

export default ProductNotes;
