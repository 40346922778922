import React from "react";
import { NavigateNext } from "@material-ui/icons";
import styles from "./Feedback.module.css";
import Link from "../../components/Link";
import { withApiContext } from "../../apis/context";
import { Form } from "../../components/Form";

const fields = [
  {
    name: "name",
    default: "",
    type: "input",
    label: "Your Name (optional)",
    placeholder: "Name"
  },
  {
    name: "email",
    type: "input",
    label: "Email",
    help:
      "Please make sure your email address is valid. We respond to all submitted forms.",
    placeholder: "you@email.com",
    default: "",
    required: true
  },
  {
    name: "body",
    required: true,
    type: "textarea",
    label: "Message",
    placeholder: "Your message..."
  }
];

class Feedback extends React.PureComponent {
  state = {
    submitting: false,
    submitted: false
  };

  handleSubmit = (formData, setFormErrors) => {
    const { apiResolver } = this.props;

    this.setState({ submitting: true, submitted: false });
    apiResolver
      .getFeedbackApi()
      .create({
        params: formData
      })
      .then(() => this.setState({ submitting: false, submitted: true }))
      .catch(err => {
        setFormErrors(err);
        this.setState({ submitting: false });
      });
  };

  render() {
    const { message = "" } = this.props;
    const { submitting, submitted } = this.state;

    const validator = formData => {
      return /\S+@\S+\.\S+/.test(formData.email);
    };

    return (
      <section className={styles.Container}>
        <h4>Submit Feedback</h4>

        {submitted && (
          <p>
            Thanks for sharing your feedback with us or letting us know about a
            bug on the site. If you provided an email address, we may contact
            you if we have questions about the issue you are experiencing.
          </p>
        )}

        {!submitted && (
          <>
            <p>
              You may use this form to report bugs, errors, differences in
              ingredient lists, and any other feedback you have about CurlScan.
              You can also submit your product questions to us and we'll do our
              best to help.
            </p>

            <p>
              If you are looking to report missing products you{" "}
              <Link to="/submit">can do that here</Link>.
            </p>

            <Form
              initialValues={{ body: message }}
              onSubmit={this.handleSubmit}
              saving={submitting}
              fields={fields}
              submitText="Submit"
              submitIcon={<NavigateNext />}
              validator={validator}
            />
          </>
        )}
      </section>
    );
  }
}

export default withApiContext()(Feedback);
