import React from "react";
import { FormControl, Typography, Button } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { shape } from "prop-types";
import qs from "query-string";
import toArray from "lodash/toArray";

import history from "../../history";
import { withApiContext } from "../../apis/context";

import { FormFields } from "../../components/Form";
import ApprovedSearch from "../../components/ApprovedSearch";
import TabMenu from "../../components/TabMenu";

class Advanced extends React.Component {
  constructor(props) {
    super(props);

    const {
      search,
      category,
      ingredientFilter,
      notIngredientFilter
    } = props.filters;

    this.state = {
      categories: [],
      loading: false,
      categoriesLoaded: false,
      filtersLoaded: false,
      filtersLoading: false,
      ingredientFilters: [],
      formData: {
        search: search || "",
        category: category || "",
        ingredientFilter: toArray(ingredientFilter),
        notIngredientFilter: toArray(notIngredientFilter)
      }
    };
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchIngredientFilters();
  }

  fetchCategories = () => {
    const { apiResolver } = this.props;

    this.setState({ loading: true });
    apiResolver
      .getCategoryApi()
      .list({
        pagination: { page: 1, size: 100 },
        filters: {
          private: false
        }
      })
      .then(page => {
        this.setState({
          categories: page.results,
          loading: false,
          categoriesLoaded: true
        });
      })
      .catch(() => {
        this.setState({ categories: [], loading: false });
      });
  };

  fetchIngredientFilters = () => {
    const { apiResolver } = this.props;

    this.setState({ filtersLoading: true });
    apiResolver
      .getIngredientFilterApi()
      .list({
        pagination: { page: 1, size: 100 },
        filters: {
          private: false
        }
      })
      .then(page => {
        this.setState({
          ingredientFilters: page.results,
          filtersLoading: false,
          filtersLoaded: true
        });
      })
      .catch(() => {
        this.setState({ ingredientFilters: [], filtersLoading: false });
      });
  };

  sanitizeIngredientFilters = (filterIds, flagName) => {
    const { ingredientFilters } = this.state;
    let filterIdList = [""];
    if (filterIds) {
      filterIdList = typeof filterIds === "object" ? filterIds : [filterIds];
    }

    return ingredientFilters.filter(
      f => f[flagName] && filterIdList.includes(`${f.id}`)
    );
  };

  handleFormChange = values => {
    this.setState(state => ({
      formData: { ...state.formData, ...values }
    }));
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const { formData } = this.state;
    history.push(`/approved/advanced?${qs.stringify(formData)}`);
  };

  parseSearchFiltersAndLabel = () => {
    const { filters = {} } = this.props;
    const { categories, categoriesLoaded, filtersLoaded } = this.state;

    const {
      category = "",
      ingredientFilter = "",
      notIngredientFilter = "",
      search = ""
    } = filters;

    // Wait until filters finished loading before parsing
    if (filtersLoaded && categoriesLoaded) {
      const matchedCategory = categories.find(
        cat => cat.id === Number(category)
      );

      const matchedIngredientFilter = this.sanitizeIngredientFilters(
        ingredientFilter,
        "include"
      );
      const matchedNotIngredientFilter = this.sanitizeIngredientFilters(
        notIngredientFilter,
        "exclude"
      );

      const notEmpty =
        matchedCategory ||
        matchedIngredientFilter.length ||
        matchedNotIngredientFilter.length ||
        search;

      if (notEmpty) {
        const parsedFilters = {
          category: matchedCategory ? matchedCategory.id : undefined,
          ingredient_filter: matchedIngredientFilter.map(f => f.id),
          not_ingredient_filter: matchedNotIngredientFilter.map(f => f.id),
          search
        };

        const label = [
          matchedCategory && `Product Category: ${matchedCategory.name}`,
          matchedIngredientFilter.length > 0 &&
            `Include Ingredients: ${matchedIngredientFilter
              .map(f => f.name)
              .join(", ")}`,
          matchedNotIngredientFilter.length > 0 &&
            `Exclude Ingredients: ${matchedNotIngredientFilter
              .map(f => f.name)
              .join(", ")}`,
          search && `Including keyword: '${search}'`
        ]
          .filter(v => v)
          .join(" and ");

        return [parsedFilters, label];
      }
    }

    return [null, null];
  };

  render() {
    const {
      loading,
      categories,
      ingredientFilters,
      filtersLoading,
      formData
    } = this.state;

    const [parsedFilters, label] = this.parseSearchFiltersAndLabel();

    return (
      <section>
        <TabMenu activeKey={TabMenu.TAB_ADVANCED} />
        <div>
          <p>
            Use the advanced search to find a type of product with or without
            specific ingredients. The <b>Keyword</b> box can be used to narrow
            down results by brand name or product type (e.g., Giovanni, gel,
            cream, etc.).
          </p>
          <form onSubmit={this.handleFormSubmit}>
            <FormFields
              data={formData}
              onChange={this.handleFormChange}
              fields={[
                {
                  type: "select",
                  name: "category",
                  label: "Product Category",
                  fieldProps: {
                    options: categories.map(cat => ({
                      name: cat.name,
                      value: cat.id
                    }))
                  }
                },
                {
                  type: "multiple",
                  name: "ingredientFilter",
                  label: "Include these ingredients",
                  fieldProps: {
                    options: ingredientFilters
                      .filter(f => f.include)
                      .map(f => ({
                        name: f.name,
                        value: `${f.id}`
                      }))
                  }
                },
                {
                  type: "multiple",
                  name: "notIngredientFilter",
                  label: "Exclude these ingredients",
                  fieldProps: {
                    options: ingredientFilters
                      .filter(f => f.exclude)
                      .map(f => ({
                        name: f.name,
                        value: `${f.id}`
                      }))
                  }
                },
                {
                  type: "input",
                  name: "search",
                  label: "Keyword"
                }
              ]}
            />

            <Typography component="div" align="right">
              <FormControl margin="normal">
                <Button
                  variant="contained"
                  color="default"
                  size="large"
                  startIcon={<Search />}
                  type="submit"
                  disabled={loading || filtersLoading}
                >
                  Search
                </Button>
              </FormControl>
            </Typography>
          </form>

          <br />
          {parsedFilters && (
            <ApprovedSearch
              filterKey="advanced"
              filter={parsedFilters}
              label={label}
            />
          )}
        </div>
      </section>
    );
  }
}

Advanced.propTypes = {
  apiResolver: shape().isRequired,
  filters: shape({}).isRequired
};

Advanced.defaultProps = {};

export default withApiContext()(Advanced);
