import React, { PureComponent } from "react";
import * as Sentry from "@sentry/react";
import { Close, SwitchVideo } from "@material-ui/icons";
import styles from "./BarcodeScanner.module.css";
import Link from "./Link";

const log = message =>
  Sentry.addBreadcrumb({
    category: "debug",
    message,
    level: 'info'
  });

const STEPS = {
  INIT: "init",
  UNSUPPORTED: "unsupported",
  STREAM_LOADING: "stream_loading",
  STREAMING: "streaming",
  CAPTURING: "capturing",
  CAPTURED: "captured",
  REQUESTING: "requesting",
  STOPPED: "stopped",
  FAILED_CONSTRAINT: "failed_constraint",
  NO_VIDEO_DEVICES: "no_video_devices",
  DENIED: "denied"
};

const STEP_LABELS = {
  [STEPS.INIT]: "Initializing...",
  [STEPS.UNSUPPORTED]:
    "Camera not supported by your browser. Please try again in a different browser.",
  [STEPS.STREAM_LOADING]: "Camera stream loading...",
  [STEPS.STREAMING]: "Streaming...",
  [STEPS.CAPTURING]: "Tap to take picture...",
  [STEPS.CAPTURED]: "Captured Image",
  [STEPS.REQUESTING]:
    "Requesting access to camera, please accept when prompted.",
  [STEPS.STOPPED]: "Paused",
  [STEPS.FAILED_CONSTRAINT]:
    "Oops. Something went wrong requesting access to your camera. Please try switching to a different camera by clicking here ➞",
  [STEPS.NO_VIDEO_DEVICES]: "No cameras detected, or access to camera denied.",
  [STEPS.DENIED]:
    "Access to camera denied. You need to allow camera for barcode scanner to work."
};

const LAST_DEVICE_SETTINGS_KEY = "lastDeviceSettings";

export default class BarcodeScanner extends PureComponent {
  static findLastDeviceIn(videoDevices) {
    let lastDeviceSettingsString = null;

    try {
      lastDeviceSettingsString = localStorage.getItem(LAST_DEVICE_SETTINGS_KEY);
    } catch (err) {
      log(`Error reading from localStorage`);
    }

    let settings = null;

    if (lastDeviceSettingsString) {
      try {
        settings = JSON.parse(lastDeviceSettingsString);
        log(`Looking for previous device with settings: ${settings}`);
      } catch {
        log("Failed to parse last device settings");
        return undefined;
      }
      const lastDevice = BarcodeScanner.findDeviceByCriteria(
        videoDevices,
        settings
      );

      if (lastDevice) {
        log(`Found last device with id: ${lastDevice.deviceId}`);
        return lastDevice.deviceId;
      }
    } else {
      log("No last device settings found");
    }
    return undefined;
  }

  static findDeviceByCriteria(videoDevices, { deviceId, label }) {
    return videoDevices.find(value => {
      if (deviceId && value.deviceId && deviceId === value.deviceId) {
        // matched on device Id
        return true;
      } else if (label && value.label && label === value.label) {
        // matched on label
        return true;
      }
      // not a match
      return false;
    });
  }

  static findBackFacingDevice(videoDevices) {
    return videoDevices.find(({ label }) => /(rear|back|envir)/im.exec(label));
  }

  static filterVideoDevices(devices) {
    if (devices && devices.length) {
      return devices.filter(device => device.kind === "videoinput");
    }
    return [];
  }

  state = {
    step: STEPS.INIT,
    videoDevices: [],
    activeVideoDeviceId: null
  };

  constructor() {
    super();
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.handleInitialize();
    this.videoRef.current.scrollIntoView();
  }

  componentWillUnmount() {
    this.handleStop();
  }

  handleInitialize = () => {
    log("Initializing barcode scanner");

    if (navigator && navigator.mediaDevices) {
      // Try to enumerate the devices, this won't work for Safari thought, until getUserMedia call
      // On firefox android it will only return the audio devices until after getUserMedia call
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          const videoDevices = BarcodeScanner.filterVideoDevices(devices);
          if (videoDevices.length) {
            this.handleFoundVideoDevices(videoDevices);
          } else {
            this.handleNoDevicesFound();
          }
        })
        .catch(() => this.handleNoDevicesFound());
    } else {
      // No mediaDevices API
      this.setState({ step: STEPS.UNSUPPORTED });
    }
  };

  handleNoDevicesFound = () => {
    log("No devices found, attempting to fetch stream anyway.");
    // iphone ?
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        // Great, we do actually have access to devices. Let's stop this stream and enumerate again now we have permission
        stream.getVideoTracks().forEach(track => track.stop());

        // Now we have should access to devices list
        navigator.mediaDevices
          .enumerateDevices()
          .then(devices => {
            const videoDevices = BarcodeScanner.filterVideoDevices(devices);
            this.handleFoundVideoDevices(videoDevices);
          })
          .catch(err => {
            log(`Failed to find devices on second attempt: ${err.message}`);
            this.setState({ step: STEPS.DENIED, videoDevices: [] });
          });
      })
      .catch(err => () => {
        log(`Failed to get user media: ${err.message}`);
        this.setState({ step: STEPS.DENIED, videoDevices: [] });
      });
  };

  handleFoundVideoDevices = videoDevices => {
    this.setState({ videoDevices });

    if (videoDevices.length === 0) {
      log("No video devices found");
      this.setState({ step: STEPS.NO_VIDEO_DEVICES });
    } else {
      log(
        `Found ${videoDevices.length} video device(s): ${JSON.stringify(
          videoDevices
        )}`
      );
      const lastDeviceId = BarcodeScanner.findLastDeviceIn(videoDevices);

      // NOTE: Don't request undefined values for deviceId as it can cause failed constraints
      if (lastDeviceId) {
        this.handleChosenVideoDevice(lastDeviceId);
      } else {
        const rearVideoDevice = BarcodeScanner.findBackFacingDevice(
          videoDevices
        );
        if (rearVideoDevice) {
          this.handleChosenVideoDevice(rearVideoDevice.deviceId);
        } else {
          this.requestRearFacingDevice();
        }
      }
    }
  };

  handleChosenVideoDevice = videoDeviceId => {
    this.setState({ step: STEPS.REQUESTING });

    const constraints = { video: { deviceId: { ideal: videoDeviceId } } };

    log(
      `Requesting access to device with constraints: ${JSON.stringify(
        constraints
      )}`
    );
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.handleAcquiredStream(stream);
      })
      .catch(err => {
        log(
          `Failed to retrieve device with constraints: ${JSON.stringify(
            constraints
          )} ${err} ${err.code} ${err.message}`
        );
        this.setState({ step: STEPS.FAILED_CONSTRAINT });
      });
  };

  requestRearFacingDevice = () => {
    const constraints = { video: { facingMode: { ideal: "environment" } } };

    log(`Requesting environment facing mode video device`);
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.handleAcquiredStream(stream);
      })
      .catch(err => {
        log(
          `Failed to retrieve environment facing device ${err} ${err.code} ${err.message}`
        );
        this.setState({ step: STEPS.FAILED_CONSTRAINT });
      });
  };

  handleAcquiredStream = stream => {
    log("Acquired stream, starting streaming");
    this.setState({ step: STEPS.STREAM_LOADING });
    this.handleStartStreaming(stream);
  };

  handleStartStreaming = stream => {
    log("Streaming commencing");

    const video = this.videoRef.current;

    video.removeEventListener("loadedmetadata", this.handleVideoMetaDataLoaded);
    video.addEventListener("loadedmetadata", this.handleVideoMetaDataLoaded);
    video.srcObject = stream;
  };

  handleVideoMetaDataLoaded = () => {
    log("Stream metadata loaded");
    const video = this.videoRef.current;

    // For Safari
    if (video) video.play();

    this.setState({ step: STEPS.STREAMING });
    this.handleStartCapturing();
  };

  handleStartCapturing = () => {
    let videoTracks = [];

    try {
      const video = this.videoRef.current;
      videoTracks = video.srcObject.getVideoTracks();
    } catch (exc) {
      // Sentry.captureException(exc);
    }

    let deviceId = null;
    let label = null;

    if (videoTracks.length) {
      const track = videoTracks[0];
      label = track.label;

      // Try to get device id from settings
      try {
        deviceId = track.getSettings().deviceId;
      } catch {
        deviceId = null;
      }

      // Firefox doesn't supply deviceId, so we have to use stream label as fallback
      const device = BarcodeScanner.findDeviceByCriteria(
        this.state.videoDevices,
        {
          label,
          deviceId
        }
      );

      if (device) {
        // Save device to local storage as the last device
        deviceId = device.deviceId;
      } else {
        deviceId = null;
        log(`Streaming from unknown device with label: ${track.label}`);
      }
    } else {
      deviceId = null;
    }

    try {
      localStorage.setItem(
        LAST_DEVICE_SETTINGS_KEY,
        JSON.stringify({ deviceId, label })
      );
    } catch (err) {
      log("Failed to write to localStorage");
    }
    log(`Streaming from device with id: ${deviceId} ${label}`);

    this.setState({ step: STEPS.CAPTURING, activeVideoDeviceId: deviceId });
  };

  handleCancel = () => {
    this.handleStop();
    this.props.onCancel();
  };

  handleStop = () => {
    log("Stopping stream");
    this.setState({ step: STEPS.STOPPED });

    if (this.timer) clearTimeout(this.timer);

    const video = this.videoRef.current;
    if (video.srcObject) {
      video.srcObject.getVideoTracks().forEach(track => track.stop());
    }
    video.srcObject = null;
  };

  handleClickVideo = () => {
    if (this.state.step === STEPS.CAPTURING) {
      this.handleSnapshot();
    }
  };

  handleSnapshot = () => {
    const video = this.videoRef.current;

    const { videoWidth, videoHeight } = video;

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = videoWidth;
    tempCanvas.height = videoHeight;

    tempCanvas
      .getContext("2d")
      .drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      );

    this.setState({ step: STEPS.CAPTURED });

    const { onScan } = this.props;

    onScan(tempCanvas.toDataURL("image/png")).catch(() =>
      this.handleStartCapturing()
    );
  };

  handleSwitchVideo = () => {
    this.handleStop();

    // Remove any existing device preference since user requested change
    try {
      localStorage.removeItem(LAST_DEVICE_SETTINGS_KEY);
    } catch (err) {
      log("Failed to remove from localStorage");
    }

    const { videoDevices, activeVideoDeviceId } = this.state;

    const currentIdx = videoDevices.findIndex(
      device => device.deviceId === activeVideoDeviceId
    );
    const nextIdx = videoDevices.length > currentIdx + 1 ? currentIdx + 1 : 0;
    const nextDeviceId = videoDevices[nextIdx].deviceId;
    this.setState({ activeVideoDeviceId: nextDeviceId });
    this.handleChosenVideoDevice(nextDeviceId);
  };

  render() {
    const { decoding, decodeResult } = this.props;

    const { step, videoDevices } = this.state;
    return (
      <div className={styles.Container}>
        <video
          id="video"
          ref={this.videoRef}
          autoPlay
          className={styles.Video}
          controls={false}
          playsInline
          width={400}
          height={300}
          muted
          onClick={this.handleClickVideo}
        />

        <section className={styles.Instructions}>
          <div className={styles.Message}>
            Align & focus barcode within screen, then click or tap image to
            scan. <Link to="/faq#troubleshooting">Need Help?</Link>
          </div>
          <div className={styles.Close} onClick={this.handleCancel}>
            <Close />
          </div>
        </section>

        <section className={styles.Actions}>
          <div className={styles.Message}>
            {STEP_LABELS[step]} &nbsp;
            {decoding && "Decoding..."}
            {decodeResult === false && "Decoding failed. Please try again"}
          </div>
          <div className={styles.Close}>
            {videoDevices.length > 1 && (
              <div className={styles.Close} onClick={this.handleSwitchVideo}>
                <SwitchVideo />
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}
