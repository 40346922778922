import React from "react";
import styles from "./ApprovedMark.module.css";

const ApprovedMark = ({ approved = null, size = "s" }) => {
  const icon = {
    true: "✓",
    false: "✗",
    null: "?"
  }[approved];

  const colorClass = {
    true: styles.Approved,
    false: styles.NotApproved,
    null: styles.Unknown
  }[approved];

  const sizeClass = size === "s" ? styles.Small : styles.Large;

  return (
    <div className={`${styles.Mark} ${sizeClass} ${colorClass}`}>{icon}</div>
  );
};

export default ApprovedMark;
