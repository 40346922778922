import React from "react";
import { shape } from "prop-types";
import { withApiContext } from "../apis/context";
import Link from "./Link";

class ProductMetaData extends React.Component {
  state = {
    metaData: null
  };

  componentDidMount() {
    this.fetchMetaData();
  }

  fetchMetaData = () => {
    const { apiResolver } = this.props;

    apiResolver
      .getProductApi()
      .meta()
      .then(metaData => this.setState({ metaData }))
      .catch(() => this.setState({ metaData: null }));
  };

  render() {
    const { metaData } = this.state;

    if (!metaData) return null;

    const { last_updated_at, total } = metaData;

    const date = new Date(last_updated_at);

    return (
      <div style={{display: 'flex', minHeight: 65, alignItems: 'center', justifyContent: 'center'}}>
      <p>
        Products Listed: <b>{total}</b>
        <br />
        Last Updated: {date.toLocaleDateString()}
        <br />
        <Link to="/latest">View Latest Products</Link>
      </p>
      </div>
    );
  }
}

ProductMetaData.propTypes = {
  apiResolver: shape().isRequired
};

export default withApiContext()(ProductMetaData);
