import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import ButtonLink from "./ButtonLink";
import SimpleMenu from "./SimpleMenu";
import Config from "../config";

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1
  },
  root: {
    marginBottom: "1em"
  }
}));

const AppNavBar = ({ isAuthenticated, onLogout, isAdmin }) => {
  const classes = useStyles();

  if (!isAuthenticated && Config.hideRegistration) return null;

  const getMenuItems = () => [
    { name: "My Profile", to: "/profile", visible: isAuthenticated },
    { name: "My Cabinets", to: "/cabinets", visible: isAuthenticated },
    { name: "My Ratings", to: "/rated", visible: isAuthenticated },

    { name: "Manage Products", to: "/manage/products", visible: isAdmin },
    { name: "Manage Filters", to: "/manage/filters", visible: isAdmin },
    { name: "Manage User Content", to: "/manage/submissions", visible: isAdmin },
    { name: "Site Settings", to: "/manage/site-settings", visible: isAdmin }
  ];

  return (
    <AppBar
      position="static"
      color={isAuthenticated ? "primary" : "default"}
      classes={{ root: classes.root }}
    >
      <Toolbar>
        {isAuthenticated && (
          <SimpleMenu
            renderButton={onClick => (
              <IconButton edge="start" color="inherit" onClick={onClick}>
                <MenuIcon />
              </IconButton>
            )}
            items={getMenuItems()}
          />
        )}

        <div className={classes.title} />

        {isAuthenticated && (
          <Button color="inherit" onClick={onLogout}>
            Logout
          </Button>
        )}
        {!isAuthenticated && (
          <>
            <ButtonLink color="inherit" to="/register">
              Register
            </ButtonLink>
            <ButtonLink color="inherit" to="/login">
              Login
            </ButtonLink>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppNavBar;
