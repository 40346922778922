import React from "react";

import Layout from "../../components/Layout";
import Latest from "./Latest";

async function action() {
  return {
    title: "CurlScan - Latest Products",
    component: (
      <Layout>
        <Latest />
      </Layout>
    )
  };
}

export default action;
