import React, { Component } from "react";
import "../theme.css";
import styles from "./Layout.module.css";
import Logo from "./Logo";
import { Instagram, Facebook } from "../icons";
import Link from "./Link";
import history from "../history";
import { withApiContext } from "../apis/context";
import AppNavBar from "./AppNavBar";
import { HorizontalAd } from "./AdUnits";

class Layout extends Component {
  handleLogout = () => {
    const { apiResolver } = this.props;
    apiResolver.logout();
    history.push("/login");
  };

  render() {
    const { apiResolver, fullWidth = false } = this.props;

    const loggedIn = apiResolver.isAuthenticated();
    const isAdmin = apiResolver.isAdmin();

    return (
      <div
        className={`LightTheme ${styles.App} ${
          fullWidth ? styles.FullWidth : styles.Centered
        }`}
      >
        <AppNavBar
          isAuthenticated={loggedIn}
          isAdmin={isAdmin}
          onLogout={this.handleLogout}
        />
        <header className={styles.AppHeader}>
          <Logo />
        </header>
        <main className={styles.AppBody}>{this.props.children}</main>

        <footer className={styles.AppFooter}>
          <div>
            <img alt="CurlScan" src="/curlscanline.divider.png" height={50} width={63}/>
          </div>

          <HorizontalAd />
          <br />

          <section className={styles.FooterLinks}>
            <Link to="/blog">Blog</Link> |{" "}
            <Link to="/privacy">Privacy Policy</Link> |{" "}
            <Link to="/faq">Help</Link> | <Link to="/submit">Submit</Link> |{" "}
            <Link to="/feedback">Feedback and Bug Report</Link> |{" "}
            <Link to="/tos">Terms of Service</Link>
            <br />
            <br />
            <Link external to=" https://www.instagram.com/curlscan/">
              <Instagram /> Instagram
            </Link>{" "}
            |{" "}
            <Link external to="https://www.facebook.com/curlscan">
              <Facebook /> Facebook
            </Link>
          </section>

          <section className={styles.AppDisclaimer}>
            <p>
              CurlScan and CurlScan.com is not associated with Lorraine Massey,
              the Curly Girl Method (“CGM”), or{" "}
              <a href="https://amzn.to/2J4xnhL">Curly Girl: The Handbook</a>.
              This app was built to make finding CG products faster and easier.
              Approval status provided is for informational purposes only and is
              accurate to the best of our knowledge, however it is not
              guaranteed. Product formulas can change at any time or vary
              between countries.{" "}
              <b>
                Always check your product for{" "}
                <Link
                  external
                  to="https://www.curlsbot.com/curly-girl-ingredient-list"
                >
                  unapproved ingredients
                </Link>
              </b>
              .
            </p>
            <p>
              CurlScan is an Amazon Associate. We may earn a small commission
              from any purchase you make after clicking on an Amazon link on our
              website. This helps us keep the site free and available for all to
              use! Please be sure you are purchasing from a reputable seller if
              ordering online. Thank you for your support.
            </p>
          </section>
        </footer>
      </div>
    );
  }
}

export default withApiContext()(Layout);
