import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import history from "../history";
import { COUNTRIES, nameForValue, POROSITY_CHOICES } from "../const";
import CabinetProduct from "../routes/cabinet/CabinetProduct";

const CabinetDetails = ({ cabinet, isOwn = false }) => {
  const { description, name, cabinet_products, user_profile } = cabinet;
  const { display_name, hair_type, country_alpha_2, porosity } =
    user_profile || {};

  const renderCountryName = code => {
    const country = COUNTRIES.find(v => v.value === code);
    return country ? country.name : "Somewhere";
  };

  const renderProfileInfo = () => {
    return (
      <p>
        A Cabinet created by <b>{display_name || "Anonymous"}</b>
        {country_alpha_2 && (
          <>
            , from <b>{renderCountryName(country_alpha_2)}</b>
          </>
        )}
        {hair_type && (
          <>
            , with curly hair type: <b>{hair_type.description}</b>
          </>
        )}
        {!!porosity && (
          <>
            &nbsp;and porosity:&nbsp;
            <b>{nameForValue(POROSITY_CHOICES, porosity)}</b>
          </>
        )}
        .
      </p>
    );
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {name}
        {isOwn && (
          <IconButton
            onClick={() => history.push(`/cabinets/${cabinet.id}/edit`)}
          >
            <Edit />
          </IconButton>
        )}
      </Typography>

      <Typography color="textSecondary" gutterBottom>
        User Profile
      </Typography>
      <Typography component="section" variant="body1" paragraph>
        {renderProfileInfo()}
      </Typography>

      <Typography color="textSecondary" gutterBottom>
        About this Cabinet
      </Typography>
      <Typography variant="body1" paragraph>
        {description || <em>This Cabinet has no description.</em>}
      </Typography>
      <br />
      <Typography variant="h5">Products</Typography>

      {cabinet_products.map(cabinetProduct => (
        <CabinetProduct
          key={cabinetProduct.id}
          cabinetProduct={cabinetProduct}
          displayName={display_name || "Cabinet Owner"}
        />
      ))}

      {cabinet_products.length === 0 && (
        <p>
          {isOwn ? (
            <>
              You have not added any products to this Cabinet yet! To add a
              product, first find it using the search and then click on
              &quot;Manage Cabinets&quot; and select the cabinet to add it to.
            </>
          ) : (
            <>No products have been added to this Cabinet yet!</>
          )}
        </p>
      )}
    </div>
  );
};

export default CabinetDetails;
