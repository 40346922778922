import React from "react";

import Layout from "../../components/Layout";
import Feedback from "./Feedback";

async function action({ query }) {
  return {
    title: "CurlScan - Submit Feedback",
    component: (
      <Layout>
        <Feedback message={query.message} />
      </Layout>
    )
  };
}

export default action;
