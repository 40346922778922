import Cookies from "js-cookie";
import createFetch from "../createFetch";
import {
  ProductApi,
  FeedbackApi,
  CategoryApi,
  IngredientFilterApi,
  HairTypeApi,
  BarcodeApi,
  ManageFilterIngredientApi,
  ManageIngredientApi,
  ManageIngredientFilterApi,
  ManageProductApi,
  BlogPostApi,
  SiteSettingApi,
  UserProfileApi,
  CabinetApi,
  SubmissionApi,
  ProductRankingApi
} from "./index";
import ProductReviewApi from "./product_review";
import UserApi from "./user";
import ReviewedProductApi from "./reviewed_product";
import AuthTokenApi from "./auth_token";
import LocationApi from "./location";

export default class ApiResolver {
  static AUTH_COOKIE_NAME = "auth";

  constructor(apiUrl) {
    this.fetch = createFetch(window.fetch, {
      apiUrl,
      onBadAuth: () => this.clearAuth()
    });
    this.initAuthFromCookie();
  }

  initAuthFromCookie() {
    const token = Cookies.getJSON(ApiResolver.AUTH_COOKIE_NAME);
    if (token && typeof token === "object") {
      this.initAuth(token);
    }
  }

  initAuth(auth) {
    this.auth = auth;
    Cookies.set(ApiResolver.AUTH_COOKIE_NAME, auth, { secure: false });
  }

  logout() {
    if (this.auth) {
      this.getAuthTokenApi().logout({});
    }
    this.clearAuth();
  }

  clearAuth() {
    this.auth = null;
    Cookies.remove(ApiResolver.AUTH_COOKIE_NAME);
  }

  isAuthenticated() {
    return !!this.auth;
  }

  isAdmin() {
    return this.auth && this.auth.user.is_staff;
  }

  isAuthenticatedOrError() {
    if (!this.isAuthenticated()) {
      const error = new Error("You must be logged in to continue.");
      error.status = 401;
      throw error;
    }
  }

  isAdminOrError() {
    this.isAuthenticatedOrError();
    if (!this.isAdmin()) {
      const error = new Error("You must be an admin to continue.");
      error.status = 403;
      throw error;
    }
  }

  getUser() {
    return this.auth ? this.auth.user : undefined;
  }

  getUserId() {
    const user = this.getUser();
    return user ? user.id : undefined;
  }

  getProductApi() {
    return new ProductApi(this);
  }

  getProductRankingApi() {
    return new ProductRankingApi(this);
  }

  getAuthTokenApi() {
    return new AuthTokenApi(this);
  }

  getBarcodeApi() {
    return new BarcodeApi(this);
  }

  getFeedbackApi() {
    return new FeedbackApi(this);
  }

  getBlogPostApi() {
    return new BlogPostApi(this);
  }

  getManageProductApi() {
    return new ManageProductApi(this);
  }

  getCategoryApi() {
    return new CategoryApi(this);
  }

  getManageIngredientApi() {
    return new ManageIngredientApi(this);
  }

  getManageIngredientFilterApi() {
    return new ManageIngredientFilterApi(this);
  }

  getManageFilterIngredientApi() {
    return new ManageFilterIngredientApi(this);
  }

  getIngredientFilterApi() {
    return new IngredientFilterApi(this);
  }

  getSiteSettingApi() {
    return new SiteSettingApi(this);
  }

  getUserProfileApi() {
    return new UserProfileApi(this);
  }

  getLocationApi() {
    return new LocationApi(this);
  }

  getProductReviewApi() {
    return new ProductReviewApi(this);
  }

  getHairTypeApi() {
    return new HairTypeApi(this);
  }

  getUserApi() {
    return new UserApi(this);
  }

  getReviewedProductApi() {
    return new ReviewedProductApi(this);
  }

  getCabinetApi() {
    return new CabinetApi(this);
  }

  getSubmissionApi() {
    return new SubmissionApi(this);
  }
}
