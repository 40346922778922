import React, { useState } from "react";
import { Input as InputIcon } from "@material-ui/icons";
import Link from "../../components/Link";
import { Form } from "../../components/Form";
import styles from "./PasswordLoginForm.module.css";
import history from "../../history";
import { withApiContext } from "../../apis/context";
import FacebookButton from "../../components/FacebookButton";

const fields = [
  {
    name: "email",
    type: "input",
    label: "Email",
    placeholder: "email"
  },
  {
    name: "password",
    type: "password",
    label: "Password"
  }
];

const PasswordLoginForm = ({ apiResolver }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (formData, setFormErrors) => {
    setLoading(true);
    apiResolver
      .getAuthTokenApi()
      .loginPassword({
        params: {
          ...formData
        }
      })
      .then(token => {
        setLoading(false);
        apiResolver.initAuth(token);
        history.push("/profile");
      })
      .catch(err => {
        setLoading(false);
        setFormErrors(err);
      });
  };

  return (
    <section className={styles.Container}>
      <h3>Social Login</h3>
      <p>Login with your social media account.</p>
      <FacebookButton />
      <h3>Password Login</h3>
      <Form
        fields={fields}
        onSubmit={handleSubmit}
        saving={loading}
        submitIcon={<InputIcon />}
        submitText="Login"
      />
      <h3>Don't yet have an account?</h3>
      <p>
        <Link to="/register">Click here to create one</Link>.
      </p>
      <h3>Forgot your password?</h3>
      <p>
        <Link to="/forgot">Click here to reset your password</Link>.
      </p>
    </section>
  );
};

export default withApiContext()(PasswordLoginForm);
