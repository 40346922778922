import React from "react";
import Button from "@material-ui/core/Button";
import history from "../history";

const ButtonLink = ({ to, ...rest }) => (
  <Button
    href={to}
    onClick={e => {
      e.preventDefault();
      history.push(to);
    }}
    {...rest}
  />
);

export default ButtonLink;
