import React from "react";
import { Typography } from "@material-ui/core";
import Link from "../../components/Link";

import Layout from "../../components/Layout";

export async function action() {
  return {
    title: "Page Not Found",
    component: (
      <Layout>
        <Typography variant="h3" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1">
          Oops! This page does not exist.
          <Link to="/">Go back to homepage</Link>.
        </Typography>
      </Layout>
    )
  };
}

export default action;
