import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {
  PorosityRatingTable,
  StarRatingTable
} from "../../components/StarRating";
import { withApiContext } from "../../apis/context";

const AGG_VIEWS = [
  {
    name: "By Hair Type",
    type: "hair_type"
  },
  {
    name: "By Porosity",
    type: "porosity"
  }
];

const RatingAggregates = ({ product, apiResolver }) => {
  const [aggregates, setAggregates] = useState(null);
  const [currentView, setCurrentView] = useState(AGG_VIEWS[0].type);

  useEffect(() => {
    apiResolver
      .getProductApi()
      .aggregates(product.id)
      .then(({ aggregates }) => {
        setAggregates(aggregates);
      })
      .catch(() => setAggregates(null));
  }, [apiResolver, product]);

  const onChangeView = e => {
    setCurrentView(e.target.value);
  };

  if (!aggregates) return null;

  return (
    <section>
      <form>
        <RadioGroup
          row
          name="view"
          value={currentView}
          onChange={onChangeView}
          style={{ justifyContent: "center" }}
        >
          {AGG_VIEWS.map(({ name, type }) => (
            <FormControlLabel
              key={type}
              label={name}
              control={<Radio />}
              checked={currentView === type}
              value={type}
              name="view"
            />
          ))}
        </RadioGroup>
      </form>

      {currentView === "hair_type" && (
        <StarRatingTable aggregates={aggregates.hair_type.aggregates} />
      )}
      {currentView === "porosity" && (
        <PorosityRatingTable aggregates={aggregates.porosity.aggregates} />
      )}
    </section>
  );
};

export default withApiContext()(RatingAggregates);
