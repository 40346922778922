import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { StarHalf, Star, StarOutline } from "@material-ui/icons";
import styles from "./StarRating.module.css";
import { nameForValue, POROSITY_CHOICES } from "../const";

const DynamicStar = ({ rating, middle, upper }) => {
  if (rating >= upper) return <Star />;
  if (rating >= middle) return <StarHalf />;
  return <StarOutline />;
};

export const StarRating = ({ rating }) => {
  return (
    <div title={`Rating: ${rating}`} className={styles.StarRating}>
      <DynamicStar rating={rating} middle={0.5} upper={1} />
      <DynamicStar rating={rating} middle={1.5} upper={2} />
      <DynamicStar rating={rating} middle={2.5} upper={3} />
      <DynamicStar rating={rating} middle={3.5} upper={4} />
      <DynamicStar rating={rating} middle={4.5} upper={5} />
    </div>
  );
};

export const StarRatingTable = ({ aggregates }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Hair Type</TableCell>
            <TableCell>Average Rating</TableCell>
            <TableCell># Reviews</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aggregates.map(({ hair_type, count, average }) => (
            <TableRow key={hair_type.id}>
              <TableCell>
                {hair_type.name}
                <br />
                <small>({hair_type.category})</small>
              </TableCell>
              <TableCell>
                {count > 0 ? (
                  <StarRating rating={average} />
                ) : (
                  <small>(no rating)</small>
                )}
              </TableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PorosityRatingTable = ({ aggregates }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Porosity</TableCell>
            <TableCell>Average Rating</TableCell>
            <TableCell># Reviews</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aggregates.map(({ porosity, count, average }) => (
            <TableRow key={porosity}>
              <TableCell>
                {nameForValue(POROSITY_CHOICES, porosity)}
              </TableCell>
              <TableCell>
                {count > 0 ? (
                  <StarRating rating={average} />
                ) : (
                  <small>(no rating)</small>
                )}
              </TableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
