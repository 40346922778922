import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Form } from "../../components/Form";
import { withApiContext } from "../../apis/context";
import Loading from "../../components/Loading";
import useUserProfile from "../../hooks/useUserProfile";
import TypographicLink from "../../components/TypographicLink";
import { nameForValue, POROSITY_CHOICES } from "../../const";
import Link from "../../components/Link";

const fields = [
  {
    name: "rating",
    type: "select",
    label: "Your rating",
    required: true,
    default: "",
    fieldProps: {
      pleaseSpecify: true,
      options: [
        { value: 0, name: "☆☆☆☆☆ (0 stars)" },
        { value: 1, name: "★☆☆☆☆ (1 stars)" },
        { value: 2, name: "★★☆☆☆ (2 stars)" },
        { value: 3, name: "★★★☆☆ (3 stars)" },
        { value: 4, name: "★★★★☆ (4 stars)" },
        { value: 5, name: "★★★★★ (5 stars)" }
      ]
    }
  }
];

const YourRating = ({ product, apiResolver, onChange }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [productReview, setProductReview] = useState(null);
  const [saving, setSaving] = useState(false);
  const [userProfile] = useUserProfile(apiResolver);

  const isAuthenticated = apiResolver.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      apiResolver
        .getProductReviewApi()
        .list({ filters: { own: true, product: product.id } })
        .then(({ results }) => {
          setProductReview(results.length ? results[0] : false);
        })
        .catch(() => setProductReview(false));
    } else {
      setProductReview(false);
    }
  }, [apiResolver, product, isAuthenticated]);

  const onSubmit = (formData, setFormErrors) => {
    setSaving(true);
    apiResolver
      .getProductReviewApi()
      .create({
        params: {
          product: product.id,
          ...formData
        }
      })
      .then(res => {
        setSaving(false);
        setProductReview(res);
        setEditMode(false);
        onChange();
      })
      .catch(err => {
        setSaving(false);
        setFormErrors(err);
      });
  };

  return (
    <section>
      {!isEditMode && (
        <>
          {productReview && (
            <>
              <Typography paragraph>
                Your Rating: &nbsp;
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Edit />}
                  onClick={() => setEditMode(true)}
                >
                  {productReview.rating} Star
                  {productReview.rating === 1 ? "" : "s"}
                </Button>
              </Typography>
            </>
          )}
          {productReview === false && isAuthenticated && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEditMode(true)}
            >
              Rate this Product
            </Button>
          )}
          {!isAuthenticated && (
            <Typography paragraph>
              <b>New!</b> <Link to="/login">Login</Link> to rate this product.
            </Typography>
          )}
          {productReview === null && <Loading loading />}
        </>
      )}
      {isEditMode && (
        <>
          <Typography paragraph>
            <em>
              Help keep CurlScan ratings accurate. Only rate products that you
              have actually used!
            </em>
          </Typography>

          <Typography paragraph>
            Your are reviewing <strong>{product.name}</strong>{" "}
          </Typography>

          <Typography paragraph>
            as somebody with:&nbsp;
            <TypographicLink
              variant="button"
              component="span"
              to="/profile"
              color="primary"
            >
              {userProfile && userProfile.hair_type
                ? `${userProfile.hair_type.name} (${userProfile.hair_type.category}) hair type`
                : "Unknown hair type"}
            </TypographicLink>
            &nbsp;and porosity:&nbsp;
            <TypographicLink
              variant="button"
              component="span"
              to="/profile"
              color="primary"
            >
              {userProfile
                ? nameForValue(POROSITY_CHOICES, userProfile.porosity)
                : "Unknown"}
            </TypographicLink>
          </Typography>
          <Grid container justify="center">
            <Grid item xs={12} sm={9} lg={6}>
              <Form
                initialValues={{
                  rating: productReview ? productReview.rating : undefined
                }}
                fields={fields}
                saving={saving}
                onSubmit={onSubmit}
              />
            </Grid>
          </Grid>
        </>
      )}
    </section>
  );
};

export default withApiContext()(YourRating);
