import React from "react";
import qs from "query-string";
import styles from "./FacebookButton.module.css";

const getRedirectUrl = () => {
  const params = qs.stringify({
    client_id: 422333124993237,
    redirect_uri: `https://${window.location.host}/facebook`,
    scope: "email",
    state: ""
  });

  return `https://www.facebook.com/v9.0/dialog/oauth?${params}`;
};

const FacebookButton = () => {
  return (
    <a href={getRedirectUrl()} title="Continue with Facebook">
      <img
        src="/facebook-button.png"
        className={styles.Button}
        alt="Continue with Facebook"
      />
    </a>
  );
};

export default FacebookButton;
