import React from "react";

const ApiContext = React.createContext(null);

export const withApiContext = () => WrappedComponent => {
  return props => (
    <ApiContext.Consumer>
      {apiResolver => <WrappedComponent {...props} apiResolver={apiResolver} />}
    </ApiContext.Consumer>
  );
};

export default ApiContext;
