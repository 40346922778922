import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "../history";

const SimpleMenu = ({ items, renderButton }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = item => {
    if (!item || !item.visible) return null;
    const onClick = () => {
      handleClose();
      if (item.to) history.push(item.to);
      if (item.onClick) item.onClick();
    };
    return (
      <MenuItem key={item.name} onClick={onClick}>
        {item.name}
      </MenuItem>
    );
  };

  const renderMenuItems = () => {
    return items.map(renderMenuItem);
  };

  return (
    <>
      {renderButton(handleClick)}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default SimpleMenu;
