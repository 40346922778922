import React, { useState } from "react";
import { Input as InputIcon } from "@material-ui/icons";
import { Form } from "../../components/Form";
import styles from "./ActivateForm.module.css";
import history from "../../history";
import { withApiContext } from "../../apis/context";

const fields = [
  {
    name: "email",
    type: "hidden"
  },
  {
    name: "password",
    type: "password",
    label: "New Password",
    placeholder: "password",
    default: "",
    required: true
  },
  {
    name: "password_confirm",
    type: "password",
    label: "New Password (Confirm)",
    placeholder: "password",
    default: "",
    required: true
  },
  {
    name: "key",
    type: "hidden"
  }
];

const ActivateForm = ({ apiResolver, email, activateKey }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (formData, setFormErrors) => {
    setLoading(true);
    apiResolver
      .getAuthTokenApi()
      .activatePassword({
        params: formData
      })
      .then(token => {
        apiResolver.initAuth(token);
        history.push("/profile");
      })
      .catch(err => {
        setLoading(false);
        setFormErrors(err);
      });
  };

  return (
    <section className={styles.Container}>
      <h3>Activate your CurlScan Account</h3>
      <p>
        Complete account setup for: <strong>{email}</strong>
      </p>
      <Form
        fields={fields}
        initialValues={{
          email,
          key: activateKey
        }}
        onSubmit={handleSubmit}
        saving={loading}
        submitIcon={<InputIcon />}
        submitText="Sign Up"
      />
    </section>
  );
};

export default withApiContext()(ActivateForm);
