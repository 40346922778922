import React from "react";
import { number, shape, string, oneOfType } from "prop-types";
import qs from "query-string";
import ApprovedMark from "../../components/ApprovedMark";
import AmazonLink, { AmazonSearchLink } from "../../components/AmazonLink";
import AmazonProductImage, { AmazonPlaceholderImage } from "../../components/AmazonProductImage";
import styles from "./Product.module.css";
import ProductIngredients from "./ProductIngredients";
import ProductCategories from "./ProductCategories";
import Link from "../../components/Link";
import { withApiContext } from "../../apis/context";
import IngredientFilters from "./IngredientFilters";
import ProductNotes from "./ProductNotes";
import { ProductReview } from "./ProductReview";
import AddToCabinetMenu from "./AddToCabinetMenu";
import Loading from "../../components/Loading";
import CurlWarehouseLink from "../../components/CurlWarehouseLink";
import LocationDependant from "../../components/LocationDependant";
import MoreLikeThis from "./MoreLikeThis";
import { SquareAd } from "../../components/AdUnits";

class Product extends React.PureComponent {
  state = {
    loading: false,
    product: null
  };

  componentDidMount() {
    const { productId } = this.props;
    this.fetchProduct(productId);
  }

  fetchProduct = productId => {
    const { apiResolver } = this.props;
    this.setState({ loading: true });
    apiResolver
      .getProductApi()
      .details(productId, {})
      .then(product => {
        this.setState({
          loading: false,
          product
        });
      })
      .catch(() =>
        this.setState({
          loading: false,
          product: null
        })
      );
  };

  render() {
    const {apiResolver, productId} = this.props
    const { product, loading } = this.state;

    const showRating = true;

    return (
      <section className={styles.Container}>
        <Loading loading={loading} />

        {product && (
          <>
            <h3>{product.name}</h3>
            <p>
              {product.approved ? (
                <>
                  This product <b>is</b> Curly Girl (CG) Method approved.
                </>
              ) : (
                <>
                  This product <b>is not</b> Curly Girl (CG) Method approved.
                </>
              )}
            </p>
            <ApprovedMark size="l" approved={product.approved} />
            <br />

            {product.asin && (
              <AmazonLink asin={product.asin}>
                <AmazonProductImage asin={product.asin} />
                <br />
                Read Reviews on Amazon
              </AmazonLink>
            )}
            {!product.asin && (
              <AmazonSearchLink keywords={product.name}>
                <AmazonPlaceholderImage />
                <br />
                Find this product on Amazon
              </AmazonSearchLink>
            )}
            {product.curlwarehouse_url && (
              <LocationDependant matcher={code => code === "CA"}>
                <CurlWarehouseLink
                  curlwarehouse_url={product.curlwarehouse_url}
                />
              </LocationDependant>
            )}
            <AddToCabinetMenu product={product} />
            <br />
            <br />
            <br />
            {product.approved && (
              <ProductCategories
                categories={product.categories}
                loading={loading}
              />
            )}
            <ProductIngredients
              productName={product.name}
              loading={loading}
              ingredients_text={product.ingredients_text}
            />
            {showRating && (
              <ProductReview
                product={product}
                onChange={() => this.fetchProduct(product.id)}
              />
            )}
            <ProductNotes notes={product.notes} />
            <IngredientFilters
              loading={loading}
              ingredient_filters={product.ingredient_filters}
            />

            <br />
            <small>
              If you spot an error or have a question,{" "}
              <Link
                to={`/feedback?${qs.stringify({
                  message: `RE: [${product.id}] ${product.name}`
                })}`}
              >
                please submit it here
              </Link>
              .
            </small>
            <br />
            <SquareAd/>

            <MoreLikeThis apiResolver={apiResolver} productId={productId} />
            <br />
            <br />
          </>
        )}
      </section>
    );
  }
}

Product.propTypes = {
  productId: oneOfType([string, number]).isRequired,
  apiResolver: shape({}).isRequired
};

export default withApiContext()(Product);
