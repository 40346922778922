import React from "react";
import { Link, Typography } from "@material-ui/core";
import qs from 'query-string'

export const buildAmazonUrl = asin => {
  return `https://www.amazon.com/dp/${asin}?tag=curlscan20-20`;
};


const AmazonLink = ({ asin, children }) => {
  return (
    <Typography variant="body1" paragraph>
      <Link href={buildAmazonUrl(asin)}>{children}</Link>
    </Typography>
  );
}

export default AmazonLink;

export const buildAmazonSearch = keywords => {
  const encoded = qs.stringify({keywords})
  return `https://www.amazon.com/gp/search?ie=UTF8&tag=curlscan20-20&linkCode=ur2&linkId=e1fe0cf15ff9cefc31a3e3d142bbfe55&camp=1789&creative=9325&index=beauty&${encoded}`;
};


export const AmazonSearchLink = ({keywords, children}) => {
  return (
    <Typography variant="body1" paragraph>
      <Link href={buildAmazonSearch(keywords)}>{children}</Link>
    </Typography>
  )
}