import React from "react";
import {
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@material-ui/core";
import ApprovedMark from "../../components/ApprovedMark";
import { StarRating } from "../../components/StarRating";
import AmazonProductImage from "../../components/AmazonProductImage";
import s from "./CabinetProduct.module.css";
import AmazonLink from "../../components/AmazonLink";
import ButtonLink from "../../components/ButtonLink";

const CabinetProduct = ({ cabinetProduct, displayName }) => {
  const { product, user_rating } = cabinetProduct;

  return (
    <Card style={{ marginTop: "1em" }}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Product Name
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              {product.name}
            </Typography>

            <Typography color="textSecondary" gutterBottom>
              {displayName}&apos;s rating
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {user_rating ? (
                <StarRating rating={user_rating} />
              ) : (
                <div>(Not Rated)</div>
              )}
            </Typography>

            <Typography color="textSecondary" gutterBottom component="div">
              Approval Status: <ApprovedMark approved={product.approved} />
            </Typography>
          </CardContent>
        </Grid>

        {product.asin && (
          <Grid item xs={12} sm={4} className={s.Image}>
            <AmazonLink asin={product.asin}>
              <AmazonProductImage asin={product.asin} />
            </AmazonLink>
          </Grid>
        )}

        <Grid item xs={12}>
          <CardActions className={s.Actions}>
            <ButtonLink to={`/product/${product.id}`} size="medium">
              View Details
            </ButtonLink>
            {product.asin && (
              <AmazonLink asin={product.asin}>
                <Button size="medium" variant="outlined" color="primary">
                  View on Amazon
                </Button>
              </AmazonLink>
            )}
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CabinetProduct;
