import React from "react";
import { LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";

const Loading = ({ loading }) => {
  return loading ? <LinearProgress /> : null;
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired
};
export default Loading;
