import React, { useRef, useState } from "react";
import styles from "./AmazonProductImage.module.css";

const AmazonProductImage = ({ asin, fallbackUrl='/amazon.logo.svg'}) => {

  const [missing, setMissing] = useState(null)
  const imageRef = useRef()

  const asinImageUrl = `https://images-na.ssl-images-amazon.com/images/P/${asin}.00._SCMZZZZZZZ_.jpg`

  const handleImageLoad = () => {
    // Sometimes amazon images return a 1x1 white pixel instead of an actual logo. So we detect this and fallback
    if(missing === null){
      if(imageRef.current){
        if(imageRef.current.complete && imageRef.current.naturalWidth < 10){
          setMissing(true)
        }
      }
    }
  }

  return (
    <img
      ref={imageRef}
      onLoad={handleImageLoad}
      alt="Amazon product"
      src={missing ? fallbackUrl : asinImageUrl}
      className={styles.Image}
    />
  );
}

export const AmazonPlaceholderImage = () => {
  return(
    <img
      alt="Amazon logo icon"
      src="/amazon.logo.svg"
      className={styles.Image}
    />
  )
}

export const GenericProductImage = () => {
  return(
    <img
      alt="Generic shampoo icon"
      src="/shampoo.icon.svg"
      className={styles.Image}
    />
  )
}

export default AmazonProductImage;