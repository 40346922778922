import React from "react";
import styles from "./Logo.module.css";
import Link from "./Link";

const Logo = () => {
  return (
    <div className={styles.Logo}>
      <Link to="/">
        <img
          src="/curlscan.banner.png"
          alt="CurlScan"
          width={300}
          height={124}
        />
      </Link>
      <br />
      <em>
        A community-built database of <em>Curly Girl Method</em>{" "}
        <Link to="/approved">approved products</Link>.
      </em>{" "}
    </div>
  );
};

export default Logo;
