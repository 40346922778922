import React from "react";
import { func, number, shape } from "prop-types";
import styles from "./Latest.module.css";
import { withApiContext } from "../../apis/context";
import { SearchList, SearchListItem } from "../../components/SearchList";
import { SquareAd } from "../../components/AdUnits";

class Latest extends React.PureComponent {
  state = {
    page: 1,
    hasMore: true,
    results: []
  };

  componentDidMount() {
    this.getNextPage();
  }

  getNextPage = () => {
    const { apiResolver, pageSize } = this.props;

    const { page, hasMore, results } = this.state;

    if (hasMore) {
      this.setState({ loading: true, page, hasMore, results });
      apiResolver
        .getProductApi()
        .list({
          filters: {
            last_30_days: true
          },
          ordering: "-created",
          pagination: {
            page,
            size: pageSize
          }
        })
        .then(res => {
          this.setState(state => ({
            loading: false,
            page: state.page + 1,
            results: [...state.results, ...res.results],
            hasMore: !!res.next,
            count: res.count
          }));
        })
        .catch(() => {
          this.setState({ loading: false, hasMore: true });
        });
    }
  };

  render() {
    const {
      results = [],
      count = 0,
      loading = false,
      hasMore = false
    } = this.state;

    return (
      <section className={styles.Container}>
        <h4>Latest Products</h4>
        <p>
          The following products were added to the CurlScan database within the last 30 days.
        </p>
        <div className={styles.Results}>
          <SearchList
            searched="Latest Products"
            searching={loading}
            results={results}
            hasMore={hasMore}
            count={count}
            onLoadMore={() => this.getNextPage()}
            emptyText="No products found"
            onRender={r => (
              <SearchListItem
                key={r.id}
                name={r.name}
                approved={r.approved}
                to={`/product/${r.id}`}
              />
            )}
          />
        </div>
        <SquareAd />
      </section>
    );
  }
}

Latest.propTypes = {
  apiResolver: shape({ getProductApi: func }).isRequired,
  pageSize: number
};

Latest.defaultProps = {
  pageSize: 50
};

export default withApiContext()(Latest);
