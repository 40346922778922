import React from "react";

import Layout from "../../components/Layout";
import ForgotPasswordForm from "./ForgotPasswordForm";

async function action({ apiResolver }) {
  const loggedIn = apiResolver.isAuthenticated();
  if (loggedIn) {
    return { redirect: "/" };
  }

  return {
    title: "Forgot Password",
    component: (
      <Layout>
        <ForgotPasswordForm />
      </Layout>
    )
  };
}

export default action;
