import React, { useState, useEffect } from "react";
import { withApiContext } from "../../apis/context";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import CabinetDetails from "../../components/CabinetDetails";
import CabinetVisibility from "../../components/CabinetVisibility";

const Cabinet = ({ apiResolver, shareUuid }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cabinet, setCabinet] = useState(null);

  const userId = apiResolver.getUserId();
  const isOwn = cabinet && userId === cabinet.user;

  useEffect(() => {
    setLoading(true);
    apiResolver
      .getCabinetApi()
      .shared(shareUuid, {})
      .then(setCabinet)
      .catch(() =>
        setError(
          "Unable to load this Cabinet. This Cabinet may not be publicly viewable."
        )
      )
      .finally(() => setLoading(false));
  }, [apiResolver, shareUuid]);

  return (
    <section>
      <Loading loading={loading} />
      <Error error={error} />
      {cabinet && (
        <>
          <CabinetVisibility
            cabinet={cabinet}
            showEditLink={isOwn}
          />
          <CabinetDetails cabinet={cabinet} isOwn={isOwn} />
        </>
      )}
    </section>
  );
};

export default withApiContext()(Cabinet);
