import { useState, useEffect } from "react";

const useUserProfile = apiResolver => {
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (apiResolver.isAuthenticated()) {
      apiResolver
        .getUserProfileApi()
        .list({ filters: { own: true } })
        .then(({ results }) => {
          setUserProfile(results.length ? results[0] : false);
        })
        .catch(() => setUserProfile(false));
    } else {
      setUserProfile(false);
    }
  }, [apiResolver]);

  return [userProfile, setUserProfile];
};

export default useUserProfile;
