import React from "react";
import Link from "@material-ui/core/Link";
import history from "../history";

const TypographicLink = ({ to, ...rest }) => (
  <Link
    href={to}
    onClick={e => {
      e.preventDefault();
      history.push(to);
    }}
    {...rest}
  />
);

export default TypographicLink;
