import React from "react";

import Layout from "../../components/Layout";
import Submit from "./Submit";

async function action() {
  return {
    title: "CurlScan - Submit a new Product",
    component: (
      <Layout>
        <Submit />
      </Layout>
    )
  };
}

export default action;
