import React from "react";

import Layout from "../../components/Layout";
import Cabinet from "./Cabinet";

export async function action({ params }) {
  return {
    title: "CurlScan - Cabinet",
    component: (
      <Layout>
        <Cabinet shareUuid={params.shareUuid} />
      </Layout>
    )
  };
}

export default action;
