import React from "react";

import Layout from "../../components/Layout";
import ActivateForm from "./ActivateForm";

async function action({ apiResolver, query }) {
  const loggedIn = apiResolver.isAuthenticated();
  if (loggedIn) {
    return { redirect: "/" };
  }

  return {
    title: "Activate",
    component: (
      <Layout>
        <ActivateForm email={query.email || ""} activateKey={query.key || ""} />
      </Layout>
    )
  };
}

export default action;
