import React from "react";

import Layout from "../../components/Layout";
import AtoZ from "./AtoZ";

async function action({ params }) {
  const { letter = "a" } = params;

  return {
    title: "A-Z List of Approved Products",
    component: (
      <Layout>
        <AtoZ letter={letter} />
      </Layout>
    )
  };
}

export default action;
