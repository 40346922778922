import React from "react";
import isEqual from "lodash/isEqual";
import { shape, number, string, func } from "prop-types";
import { withApiContext } from "../apis/context";

import { SearchList, SearchListItem } from "./SearchList";

class ApprovedSearch extends React.Component {
  state = {
    loading: false,
    page: 1,
    hasMore: false,
    results: []
  };

  componentDidMount() {
    this.getNextPage(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filter } = this.props;
    if (!isEqual(filter, prevProps.filter)) {
      if (filter) this.getNextPage(filter, true);
    }
  }

  getNextPage = (reset = false) => {
    const { apiResolver, filter, pageSize } = this.props;

    let { page, hasMore, results } = this.state;

    if (reset) {
      page = 1;
      hasMore = false;
      results = [];
    }

    if (filter) {
      this.setState({ loading: true, page, hasMore, results });
      apiResolver
        .getProductApi()
        .list({
          filters: {
            ...filter,
            approved: true
          },
          ordering: "name",
          pagination: {
            page,
            size: pageSize
          }
        })
        .then(res => {
          this.setState(state => ({
            loading: false,
            page: state.page + 1,
            results: reset ? res.results : [...state.results, ...res.results],
            hasMore: !!res.next,
            count: res.count
          }));
        })
        .catch(() => {
          this.setState({ loading: false, hasMore: true });
        });
    }
  };

  render() {
    const { label } = this.props;

    const {
      results = [],
      count = 0,
      loading = false,
      hasMore = false
    } = this.state;

    return (
      <SearchList
        searched={label}
        searching={loading}
        results={results}
        hasMore={hasMore}
        count={count}
        onLoadMore={() => this.getNextPage()}
        onRender={r => (
          <SearchListItem
            key={r.id}
            name={r.name}
            approved={r.approved}
            to={`/product/${r.id}`}
          />
        )}
      />
    );
  }
}

ApprovedSearch.propTypes = {
  filter: shape({}).isRequired,
  label: string.isRequired,
  apiResolver: shape({ getProductApi: func }).isRequired,
  pageSize: number
};

ApprovedSearch.defaultProps = {
  pageSize: 50
};

export default withApiContext()(ApprovedSearch);
