import React from "react";
import { arrayOf, string, func, shape } from "prop-types";
import cn from "classnames";
import styles from "./Tabs.module.css";

class Tabs extends React.Component {
  render() {
    const { tabs, activeKey, onChange } = this.props;

    return (
      <div className={styles.Container}>
        {tabs.map(config => (
          <div
            key={config.key}
            role="button"
            className={cn(
              styles.Tab,
              config.key === activeKey ? styles.Active : styles.Inactive
            )}
            onClick={() => onChange(config.key)}
          >
            {config.name}
          </div>
        ))}
      </div>
    );
  }
}

Tabs.propTypes = {
  activeKey: string.isRequired,
  tabs: arrayOf(shape({ key: string.isRequired })).isRequired,
  onChange: func.isRequired
};

Tabs.defaultProps = {};

export default Tabs;
