import React, { PureComponent } from "react";
import {
  Grid,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  Box
} from "@material-ui/core";

import { CameraAlt, Search as SearchIcon } from "@material-ui/icons";
import BarcodeSearch from "./BarcodeSearch";
import { SearchList, SearchListItem } from "../../components/SearchList";
import Link from "../../components/Link";
import styles from "./Search.module.css";
import history from "../../history";
import ApprovedMark from "../../components/ApprovedMark";
import { withApiContext } from "../../apis/context";
import LatestBlogPostPreview from "../../components/LatestBlogPostPreview";
import TabMenu from "../../components/TabMenu";
import ProductMetaData from "../../components/ProductMetaData";

const MODE_BARCODE = 0;
const MODE_PRODUCT_NAME = 1;

const Empty = () => (
  <div>
    <ApprovedMark approved={null} size="l" />
    <br />
    This product was not found in our database.
    <p>
      Use the search box above to search for this product by name if scanning
      the barcode didn't find a match. Products may be catalogued without a
      barcode if we were unable to locate one. You can submit a barcode for a
      product already in the database <Link to="/submit">here</Link>.
    </p>
  </div>
);

class Search extends PureComponent {
  static state = {
    search: "",
    searching: false,
    searched: false,
    results: [],
    mode: MODE_PRODUCT_NAME
  };

  constructor(props) {
    super(props);
    const { search } = props;
    this.state = { ...Search.state, search: search || "" };
  }

  componentDidMount() {
    const { search } = this.props;
    // Now do the search
    this.doSearch(search, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = this.props;
    if (prevProps.search !== search) {
      this.doSearch(search);
    }
  }

  handleStartScanning = () => {
    this.setState({ mode: MODE_BARCODE });
  };

  handleStopScanning = () => this.setState({ mode: MODE_PRODUCT_NAME });

  handleBarcodeSuccess = upc => {
    const { onUpdateSearch } = this.props;
    this.setState({ mode: MODE_PRODUCT_NAME });
    onUpdateSearch({ search: upc });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onUpdateSearch } = this.props;
    const { search } = this.state;
    onUpdateSearch({ search });
  };

  doSearch = (search, redirect = true) => {
    const { apiResolver } = this.props;
    const searchString = (search || "").trim();

    if (!searchString) {
      this.setState({
        search: "",
        searching: false,
        results: [],
        searched: false
      });
      return;
    }

    this.setState({ searching: true, results: [], searched: false });

    apiResolver
      .getProductApi()
      .list({
        search: searchString,
        pagination: {
          page: 1,
          size: 100
        }
      })
      .then(({ results, count }) => {
        this.setState({
          results,
          count,
          searching: false,
          searched: searchString
        });
        if (results.length === 1 && redirect) {
          history.push(`/product/${results[0].id}`);
        }
      })
      .catch(err => {
        this.setState({
          results: [],
          count: 0,
          searching: false,
          searched: searchString
        });
      });
  };

  handleChangeSearch = e => this.setState({ search: e.target.value });

  render() {
    const { results, searched, count, searching } = this.state;
    const { mode, search } = this.state;
    return (
      <article className={styles.Container}>
        <TabMenu activeKey={TabMenu.TAB_SEARCH} />
        {mode === MODE_BARCODE && (
          <BarcodeSearch
            onSuccess={this.handleBarcodeSuccess}
            onCancel={this.handleStopScanning}
          />
        )}

        {mode === MODE_PRODUCT_NAME && (
          <section>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  startIcon={<CameraAlt />}
                  onClick={this.handleStartScanning}
                  color="primary"
                  size="large"
                  variant="outlined"
                >
                  Scan Barcode
                </Button>
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={this.handleSubmit}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={9}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="search">
                          Product name or barcode
                        </InputLabel>

                        <OutlinedInput
                          color="secondary"
                          label="Product name or barcode"
                          autoFocus
                          name="search"
                          onChange={this.handleChangeSearch}
                          value={search}
                          placeholder="Product name or barcode"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        fullWidth
                        type="submit"
                        disabled={!search}
                        startIcon={<SearchIcon />}
                        color="primary"
                        variant="contained"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <br />

            <SearchList
              results={results}
              count={count}
              searched={searched}
              searching={searching}
              onRender={r => (
                <SearchListItem
                  key={r.id}
                  name={r.name}
                  approved={r.approved}
                  to={`/product/${r.id}`}
                />
              )}
              emptyText={<Empty />}
            />

            {searched && (
              <Box mb={3}>
                <small>
                  If you can’t find your favourite product in our catalogue or
                  spot an error, <Link to="/submit">please submit it here</Link>
                  .
                </small>
              </Box>
            )}
            <LatestBlogPostPreview className={styles.BlogPostPreview} />
            <ProductMetaData />
          </section>
        )}
      </article>
    );
  }
}

export default withApiContext()(Search);
