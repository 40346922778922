import React from "react";
import { Typography } from "@material-ui/core";

import Layout from "../../components/Layout";

export async function action() {
  return {
    title: "Oops!",
    component: (
      <Layout>
        <Typography variant="h3" gutterBottom>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1">Please try again later.</Typography>
      </Layout>
    )
  };
}

export default action;
