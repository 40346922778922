import BaseApi from "./base";

export default class ProductReviewApi extends BaseApi {
  baseName = "product_review";

  csv(reportType, options) {
    return this.file({
      endpoint: `${reportType}/csv`,
      fileName: `submissions.${reportType}.csv`,
      ...options
    });
  }
}
