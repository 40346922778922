import React from "react";

import Layout from "../../components/Layout";
import Product from "./Product";

async function action({ params }) {
  const { id } = params;

  // Note: key is a hack to force re-fetch of product info when switching between two product routes
  return {
    title: "Product Details",
    component: (
      <Layout>
        <Product key={id} productId={id} />
      </Layout>
    )
  };
}

export default action;
