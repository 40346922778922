import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { FileCopy } from "@material-ui/icons";

const CopyButton = ({ copyData = "", children }) => {
  const [copied, setCopied] = useState(null);

  const onClick = () => {
    const textarea = document.createElement("textarea");
    textarea.textContent = copyData;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      setCopied("✓ Copied to Clipboard!");
    } catch {
      setCopied("✗ Copy Failed!");
    } finally {
      document.body.removeChild(textarea);
    }
  };

  return (
    <Button
      size="small"
      startIcon={<FileCopy />}
      color="primary"
      onClick={onClick}
      variant="outlined"
    >
      {copied || children}
    </Button>
  );
};

export default CopyButton;
