import React from "react";
import { Typography } from "@material-ui/core";
import Link from "../../components/Link";

import Layout from "../../components/Layout";

export async function action() {
  return {
    title: "Login Required",
    component: (
      <Layout>
        <Typography variant="h3" gutterBottom>
          Login Required
        </Typography>
        <Typography variant="body1">
          You must be logged in to view this page. You can{" "}
          <Link to="/login">login here</Link>.
        </Typography>
      </Layout>
    )
  };
}

export default action;
