import React from "react";
import {
  shape,
  number,
  string,
  func,
  bool,
  arrayOf,
  oneOfType,
  node
} from "prop-types";
import {
  Typography,
  Button,
  CircularProgress,
  LinearProgress
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Link from "./Link";
import ApprovedMark from "./ApprovedMark";
import styles from "./SearchList.module.css";
import { HorizontalAd } from "./AdUnits";

export class SearchList extends React.Component {
  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Check if we have extended the results, if we have, preserve the scroll position
    if (prevProps.results.length < this.props.results.length) {
      return [
        window.pageXOffset || window.scrollX,
        window.pageYOffset || window.scrollY
      ];
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      window.scrollTo(snapshot[0], snapshot[1]);
    }
  }

  render() {
    const {
      searched = "",
      searching = false,
      results,
      count = 0,
      onRender,
      hasMore = false,
      onLoadMore = null,
      emptyText,
      adOffset = 10
    } = this.props;

    const showingCount = results.length || 0;
    const empty = count === 0 && !searching;
    const doneSearch = searched !== false;

    const resultsBeforeAd = (results || []).slice(0, adOffset)
    const resultsAfterAd = (results || []).slice(adOffset)

    return (
      <>
        {searching && <LinearProgress />}

        {doneSearch && (
          <section>
            <Typography paragraph align="center">
              <small>
                Showing {showingCount} result(s) for: <i>{searched}</i>
              </small>
            </Typography>

            <HorizontalAd />

            {resultsBeforeAd.length > 0 && (
                <ol className={styles.List}>
                  {resultsBeforeAd.map(onRender)}
                </ol>
            )}
            {resultsAfterAd.length > 0 && (
              <>
                <HorizontalAd/>
                <ol className={styles.List}>
                  {resultsAfterAd.map(onRender)}
                </ol>
              </>
            )}

            {empty && emptyText}

            {hasMore && (
              <Typography component="div" align="center">
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={
                    searching ? <CircularProgress size={15} /> : <ExpandMore />
                  }
                  onClick={onLoadMore}
                  disabled={searching}
                >
                  Load More
                </Button>
              </Typography>
            )}
          </section>
        )}
      </>
    );
  }
}

SearchList.propTypes = {
  searched: oneOfType([string, bool]).isRequired,
  searching: bool.isRequired,
  results: arrayOf(shape({})).isRequired,
  count: number,
  onRender: func.isRequired,
  hasMore: bool,
  onLoadMore: func,
  emptyText: node
};

SearchList.defaultProps = {
  hasMore: undefined,
  onLoadMore: undefined,
  emptyText: undefined,
  count: undefined
};

export const SearchListItem = ({
  name,
  approved,
  to,
  startIcon = null,
  endIcon = null
}) => {
  const getContents = () => {
    return (
      <>
        {startIcon}
        {approved !== undefined && (
          <div className={styles.IconLeft}>
            <ApprovedMark size="s" approved={approved} />
          </div>
        )}
        <Link to={to} className={styles.ListItemLink}>
          {name}
        </Link>
        {endIcon}
      </>
    );
  };
  return (
    <li
      className={`${styles.ListItem} ${
        approved ? styles.ApprovedListItem : styles.NotApprovedListItem
      }`}
    >
      {getContents()}
    </li>
  );
};
