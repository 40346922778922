import React from "react";
import { withApiContext } from "../apis/context";
import Link from "./Link";
import styles from "./LatestBlogPostPreview.module.css";

class LatestBlogPostPreview extends React.Component {
  state = {
    blogPost: null
  };

  componentDidMount() {
    this.fetchLatestBlogPost();
  }

  fetchLatestBlogPost = () => {
    const { apiResolver } = this.props;

    apiResolver
      .getBlogPostApi()
      .latest()
      .then(blogPost => this.setState({ blogPost }))
      .catch(() => this.setState({ blogPost: null }));
  };

  render() {
    const { className } = this.props;
    const { blogPost } = this.state;

    if (!blogPost) return null;

    return (
      <section className={`${className} ${styles.Container}`}>
        <p>
        <b>Latest from the CurlScan Blog: </b>
        <Link to={`/blog/${blogPost.id}/${blogPost.slug}`}>
          {blogPost.title}
        </Link>
        </p>
      </section>
    );
  }
}

export default withApiContext()(LatestBlogPostPreview);
