import React from "react";
import { arrayOf, shape, bool } from "prop-types";
import {TurnedIn} from "@material-ui/icons";
import CollapsePanel from "./CollapsePanel";
import styles from "./ProductCategories.module.css";
import Loading from "../../components/Loading";

class ProductCategories extends React.Component {
  render() {
    const { categories, loading } = this.props;

    if (loading) {
      return <Loading loading={loading} />;
    }

    if (categories) {
      return categories.map(cat => (
        <CollapsePanel
          primaryColor="650d61"
          secondaryColor="c389c1"
          key={cat.id}
          icon={<TurnedIn />}
          loading={loading}
          title={cat.name}
          className={styles.Category}
        >
          {cat.description}
        </CollapsePanel>
      ));
    }

    return null;
  }
}

ProductCategories.propTypes = {
  categories: arrayOf(shape({})),
  loading: bool.isRequired
};

ProductCategories.defaultProps = {
  categories: undefined
};

export default ProductCategories;
