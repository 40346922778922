import React from "react";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ApiContext from "./apis/context";

const theme = createTheme({
  palette: {
    primary: {
      main: "#650d61",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#c389c1"
    }
  }
});

const App = ({ children, apiResolver }) => {
  return (
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={apiResolver}>{children}</ApiContext.Provider>
    </ThemeProvider>
  );
};

export default App;
