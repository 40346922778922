import qs from "query-string";

export default class BaseApi {
  baseName = "";

  constructor(apiResolver) {
    this.apiResolver = apiResolver;
  }

  getToken() {
    return this.apiResolver.isAuthenticated()
      ? this.apiResolver.auth.token
      : "";
  }

  getById(id, { embeds = [], params = {} }) {
    const urlParams = {
      ...params,
      embeds
    };

    return this.apiResolver.fetch(
      `/${this.baseName}/${id}/?${qs.stringify(urlParams)}`,
      {
        method: "GET",
        token: this.getToken(),
        success: r => r,
        failure: err => Promise.reject(err)
      }
    );
  }

  create({
    embeds = [],
    endpoint = null,
    params = {},
    credentials = undefined
  }) {
    const urlParams = {
      embeds
    };

    const contentType = params.file
      ? "multipart/form-data"
      : "application/json";

    return this.apiResolver.fetch(
      `/${this.baseName}/${endpoint ? `${endpoint}/` : ""}?${qs.stringify(
        urlParams
      )}`,
      {
        method: "POST",
        token: this.getToken(),
        body: params,
        credentials: credentials ? "include" : undefined,
        contentType,
        success: entity => entity,
        failure: err => Promise.reject(err)
      }
    );
  }

  list({ embeds, ordering, filters, search, pagination, endpoint = null }) {
    const urlParams = {
      ordering,
      embeds,
      search,
      ...filters,
      ...(pagination
        ? { page: pagination.page, page_size: pagination.size }
        : {})
    };

    return this.apiResolver.fetch(
      `/${this.baseName}${endpoint ? `/${endpoint}` : ""}/?${qs.stringify(
        urlParams
      )}`,
      {
        method: "GET",
        token: this.getToken(),
        success: result => result,
        failure: err => Promise.reject(err)
      }
    );
  }

  action(id, { embeds = [], params = null, endpoint = null }) {
    const urlParams = {
      embeds
    };

    return this.apiResolver.fetch(
      `/${this.baseName}${
        id !== null ? `/${id}` : ""
      }/${endpoint}/?${qs.stringify(urlParams)}`,
      {
        method: "PATCH",
        token: this.getToken(),
        body: params || undefined,
        success: result => result,
        failure: err => Promise.reject(err)
      }
    );
  }

  update(id, { params = null }) {
    const urlParams = {};

    return this.apiResolver.fetch(
      `/${this.baseName}/${id}/?${qs.stringify(urlParams)}`,
      {
        method: "PATCH",
        token: this.getToken(),
        body: params || undefined,
        success: result => result,
        failure: err => Promise.reject(err)
      }
    );
  }

  retrieve(id, { endpoint, params }) {
    return this.apiResolver.fetch(
      `/${this.baseName}${
        id !== null ? `/${id}` : ""
      }/${endpoint}/?${qs.stringify(params)}`,
      {
        method: "GET",
        token: this.getToken(),
        body: params || undefined,
        success: result => result,
        failure: err => Promise.reject(err)
      }
    );
  }

  delete(id, { params } = {}) {
    const urlParams = {};

    return this.apiResolver.fetch(
      `/${this.baseName}/${id}/?${qs.stringify(urlParams)}`,
      {
        method: "DELETE",
        token: this.getToken(),
        body: params,
        success: result => result,
        failure: err => Promise.reject(err)
      }
    );
  }

  file({ endpoint, params, fileName = "download.txt" }) {
    return this.apiResolver.fetch(
      `/${this.baseName}/${endpoint}/?${qs.stringify(params)}`,
      {
        method: "GET",
        token: this.getToken(),
        body: params || undefined,
        fileName,
        success: result => result,
        failure: err => Promise.reject(err)
      }
    );
  }
}
