import { useEffect, useState } from "react";
import { withApiContext } from "../apis/context";

const LocationDependant = ({ children, matcher, apiResolver }) => {
  const [code, setCode] = useState(null);

  useEffect(() => {
    apiResolver
      .getLocationApi()
      .me()
      .then(({ country_alpha_2 }) => {
        setCode(country_alpha_2);
      })
      .catch(() => setCode(false));
  }, [apiResolver, matcher]);

  const show = code !== null ? matcher(code) : false;
  return show ? children : false;
};

export default withApiContext()(LocationDependant);
