import React from "react";
import cn from "classnames";
import { string, func, node, bool } from "prop-types";
import styles from "./CollapsePanel.module.css";
import Loading from "../../components/Loading";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

class CollapsePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: props.initialShowing
    };
  }

  handleShowHide = () => {
    const { onExpand } = this.props;
    const { showing } = this.state;
    this.setState({ showing: !showing });

    if (showing === null && onExpand) onExpand();
  };

  render() {
    const { showing } = this.state;
    const {
      loading,
      title,
      children,
      icon,
      className,
      primaryColor,
      secondaryColor
    } = this.props;

    const arrow = showing ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
    return (
      <section
        style={{ borderColor: `#${secondaryColor}` }}
        className={cn(styles.Container, className)}
      >
        <div
          style={{ color: `#${primaryColor}` }}
          className={styles.Header}
          onClick={this.handleShowHide}
        >
          {icon}
          <div>{title}</div>
          {arrow}
        </div>
        {showing && (
          <div className={styles.Body}>
            <Loading loading={loading} />
            {!loading && children}
          </div>
        )}
      </section>
    );
  }
}

CollapsePanel.propTypes = {
  className: string,
  primaryColor: string.isRequired,
  secondaryColor: string.isRequired,
  loading: bool.isRequired,
  title: node.isRequired,
  onExpand: func,
  children: node,
  icon: node.isRequired,
  initialShowing: bool
};

CollapsePanel.defaultProps = {
  children: null,
  onExpand: undefined,
  className: undefined,
  initialShowing: false
};

export default CollapsePanel;
