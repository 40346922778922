import React, { useState } from "react";
import { Input as InputIcon } from "@material-ui/icons";
import { Form } from "../../components/Form";
import styles from "./PasswordRegisterForm.module.css";
import { withApiContext } from "../../apis/context";
import Link from "../../components/Link";
import FacebookButton from "../../components/FacebookButton";

const fields = [
  {
    name: "email",
    type: "input",
    label: "Email",
    placeholder: "email"
  }
];

const PasswordRegisterForm = ({ apiResolver }) => {
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);

  const handleSubmit = (formData, setFormErrors) => {
    setLoading(true);
    apiResolver
      .getUserApi()
      .registerPassword({
        params: formData
      })
      .then(() => {
        setRegistered(formData.email);
      })
      .catch(err => {
        setLoading(false);
        setFormErrors(err);
      });
  };

  return (
    <section className={styles.Container}>
      {registered ? (
        <section>
          <h3>Email Verification Required</h3>
          <p>
            Thanks for registering. We have sent you an email to:{" "}
            <strong>{registered}</strong>. Click the link in the email to complete your
            account setup. Thank you for joining CurlScan.
          </p>
        </section>
      ) : (
        <section>
          <h3>Create your CurlScan Account</h3>
          <p>
            Registration is optional, but if you want to submit product ratings
            you will need an account. If you already have an account you can{" "}
            <Link to="/login">log in here </Link>.
          </p>
          <p>
            CurlScan only uses your email address to verify your account. We
            never send unsolicited emails, ever. Your email will not be shared
            with any third parties.
          </p>

          <h3>Social Login</h3>
          <p>Login with your social media account</p>
          <FacebookButton />

          <h3>Password Login</h3>
          <p>Login with email address and password.</p>
          <Form
            fields={fields}
            onSubmit={handleSubmit}
            saving={loading}
            submitIcon={<InputIcon/>}
            submitText="Sign Up"
          />
        </section>
      )}
    </section>
  );
};

export default withApiContext()(PasswordRegisterForm);
