import BaseApi from "./base";

export class ProductApi extends BaseApi {
  baseName = "product";

  details(id, options) {
    return this.retrieve(id, { ...options, endpoint: "details" });
  }

  meta(options) {
    return this.retrieve(null, { ...options, endpoint: "meta" });
  }

  cabinets(id, options) {
    return this.retrieve(id, { ...options, endpoint: "cabinets" });
  }

  aggregates(id, options) {
    return this.retrieve(id, { ...options, endpoint: "aggregates" });
  }

  similar(id, options) {
    return this.retrieve(id, { ...options, endpoint: "similar" });
  }
}

export class BarcodeApi extends BaseApi {
  baseName = "barcode";

  decode(options) {
    return this.action(null, { ...options, endpoint: "decode" });
  }
}

export class FeedbackApi extends BaseApi {
  baseName = "feedback";
}

export class BlogPostApi extends BaseApi {
  baseName = "blog_post";

  latest(options) {
    return this.retrieve(null, { ...options, endpoint: "latest" });
  }
}

export class ManageProductApi extends BaseApi {
  baseName = "manage/product";

  bulk(options) {
    return this.action(null, {
      endpoint: "bulk",
      ...options
    });
  }
}

export class CategoryApi extends BaseApi {
  baseName = "category";
}

export class ManageIngredientFilterApi extends BaseApi {
  baseName = "ingredient_filter";
}

export class ManageIngredientApi extends BaseApi {
  baseName = "ingredient";
}

export class ManageFilterIngredientApi extends BaseApi {
  baseName = "filter_ingredient";
}

export class IngredientFilterApi extends BaseApi {
  baseName = "ingredient_filter";
}

export class SiteSettingApi extends BaseApi {
  baseName = "site_setting";
}

export class UserProfileApi extends BaseApi {
  baseName = "user_profile";
}

export class HairTypeApi extends BaseApi {
  baseName = "hair_type";
}

export class CabinetApi extends BaseApi {
  baseName = "cabinet";

  products(id, options) {
    return this.action(id, {
      endpoint: "products",
      ...options
    });
  }

  shared(shareUuid, options) {
    return this.retrieve(null, {
      endpoint: `share/${shareUuid}`,
      ...options
    });
  }

  details(id, options) {
    return this.retrieve(id, {
      endpoint: `details`,
      ...options
    });
  }
}

export class SubmissionApi extends BaseApi {
  baseName = "submission";

  csv(submissionType, options) {
    return this.file({
      endpoint: `${submissionType}/csv`,
      fileName: `submissions.${submissionType}.csv`,
      ...options
    });
  }
}


export class ProductRankingApi extends BaseApi {
  baseName = "product_ranking";
}