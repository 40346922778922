import React from "react";
import { oneOf } from "prop-types";

import Tabs from "./Tabs";
import history from "../history";

class TabMenu extends React.Component {
  handleTabChange = tab => {
    if (tab === TabMenu.TAB_ATOZ) history.push(`/approved`);
    else if (tab === TabMenu.TAB_ADVANCED) history.push(`/approved/advanced`);
    else if (tab === TabMenu.TAB_POPULAR) history.push(`/popular`);
    else history.push(`/`);
  };

  static TAB_ATOZ = "atoz";

  static TAB_ADVANCED = "advanced";

  static TAB_SEARCH = "search";

  static TAB_POPULAR = "popular";

  render() {
    const { activeKey } = this.props;
    return (
      <Tabs
        activeKey={activeKey}
        tabs={[
          {
            key: TabMenu.TAB_SEARCH,
            name: "Search"
          },
          {
            key: TabMenu.TAB_ATOZ,
            name: "A-Z"
          },
          {
            key: TabMenu.TAB_ADVANCED,
            name: "Advanced"
          },
          {
            key: TabMenu.TAB_POPULAR,
            name: "Popular"
          }
        ]}
        onChange={this.handleTabChange}
      />
    );
  }
}

TabMenu.propTypes = {
  activeKey: oneOf([TabMenu.TAB_SEARCH, TabMenu.TAB_ATOZ, TabMenu.TAB_ADVANCED, TabMenu.TAB_POPULAR])
    .isRequired
};

TabMenu.defaultProps = {};

export default TabMenu;
