import React from "react";
import { Typography, Link } from "@material-ui/core";

const CurlWarehouseLink = ({ curlwarehouse_url }) => {
  return (
    <Typography variant="body1" paragraph>
      <img src="/ca.png" alt="Canada" style={{ height: "1em" }} />
      &nbsp; Shopping in Canada? Buy this product from&nbsp;
      <Link href={curlwarehouse_url}>Curl Warehouse</Link>. &nbsp;
      <img src="/ca.png" alt="Canada" style={{ height: "1em" }} />
    </Typography>
  );
};

export default CurlWarehouseLink;