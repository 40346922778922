import React, { useState } from "react";
import BarcodeScanner from "../../components/BarcodeScanner";
import { withApiContext } from "../../apis/context";

const BarcodeSearch = ({ onSuccess, onCancel, apiResolver }) => {
  const [decoding, setDecoding] = useState(false);
  const [result, setResult] = useState(null);

  const handleScan = b64Image => {
    setDecoding(true);
    setResult(null);
    return apiResolver
      .getBarcodeApi()
      .decode({
        params: {
          file: b64Image
        }
      })
      .then(({ upc }) => {
        setDecoding(false);
        setResult(upc);
        onSuccess(upc);
        return upc;
      })
      .catch(err => {
        setDecoding(false);
        setResult(false);
        throw err;
      });
  };

  return (
    <BarcodeScanner
      onCancel={onCancel}
      onScan={handleScan}
      decoding={decoding}
      decodeResult={result}
    />
  );
};

export default withApiContext()(BarcodeSearch);
