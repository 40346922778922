import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import qs from "query-string";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";

// Remove CRA service worker
// import * as serviceWorker from './serviceWorker';

import ApiResolver from "./apis/resolver";
import history from "./history";
import router from "./routes";
import App from "./App";

const { protocol, hostname } = document.location;

const apiUrl =
    hostname !== "localhost"
        ? `${protocol}//api.${hostname}`
        : "https://api.local.curlscan.com";

const apiResolver = new ApiResolver(apiUrl);

const rootEl = document.getElementById("root");

let previousLocationName = null;

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn:
            "https://66bc97d881c04a0591030a534a6e996c@o560439.ingest.sentry.io/5696000",
        integrations: [
            new BrowserTracing()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,
        allowUrls: [/https?:\/\/curlscan\.com/, /https?:\/\/local.curlscan\.com/],
        ignoreErrors: [
            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "Can't find variable: ez_attachEvent",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "EBCallBackMessageReceived",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
        ],
    });
}

async function onLocationChange(location) {
    let query;

    try {
        query = qs.parse(location.search.substring(1));
    } catch {
        query = {};
    }

    const route = await router.resolve({
        pathname: location.pathname,
        hash: location.hash,
        query,
        apiResolver
    });

    if (route.redirect) {
        history.push(route.redirect);
        return;
    }

    ReactDOM.render(
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <App apiResolver={apiResolver}>{route.component}</App>
        </Sentry.ErrorBoundary>,
        rootEl
    );

    const targetEl =
        location.hash && document.getElementById(location.hash.substring(1));

    if (targetEl) {
        targetEl.scrollIntoView(true);
    } else if (previousLocationName !== route.name) {
        window.scrollTo(0, 0);
    }

    previousLocationName = route.name;
    document.title = route.title;
}

history.listen(onLocationChange);

onLocationChange(history.location);

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("sw.js")
        .then(function (reg) {
            console.debug("Service Worker registered");
        })
        .catch(function (err) {
            console.debug("Service Worker Failed to register", err);
        });
}
