import React from "react";
import { string } from "prop-types";
import FilterLabel from "./FilterLabel";
import ApprovedSearch from "../../components/ApprovedSearch";
import TabMenu from "../../components/TabMenu";
import { Typography } from "@material-ui/core";

class AtoZ extends React.Component {
  labels = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];

  render() {
    const { letter } = this.props;
    return (
      <>
        <TabMenu activeKey={TabMenu.TAB_ATOZ} />

        <Typography paragraph align="center">
          {this.labels.map(l => (
            <FilterLabel
              key={l}
              label={l}
              to={`/approved/${l}`}
              selected={l === letter.toUpperCase()}
            />
          ))}
        </Typography>
        <ApprovedSearch filter={{ starts_with: letter }} label={letter} />
      </>
    );
  }
}

AtoZ.propTypes = {
  letter: string.isRequired
};

AtoZ.defaultProps = {};

export default AtoZ;
